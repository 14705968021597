import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function tellerCreate(email, firstName, lastName, gender, mobileNumber) {
  const { data } = await axios.post(routes.customersTellerCreate, {
    email,
    firstName,
    lastName,
    gender,
    mobileNumber,
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
