import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

export default async function activatePass(ticketPass) {
  const response = await axios.post(
    routes.activatePass(ticketPass),
    {},
    {
      ...AXIOS_DEFAULT_CONFIG,
    }
  );

  return response;
}
