import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG } from '../constants';

export * as auth from './auth';
export * as cashTransactions from './cashTransactions';
export * as customers from './customers';
export * as items from './items';
export * as orders from './orders';
export * as outlets from './outlets';
export * as tickets from './tickets';
export * as entryAccess from "./entryAccess"
export * as parties from "./parties"
export * as events from "./events"
export * as promos from "./promos"
export * as reports from "./reports"

export async function genericGet(url) {
  const { data } = await axios.get(url, AXIOS_DEFAULT_CONFIG);
  return data;
}
