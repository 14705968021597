export const getCartItemIndex = (cart, item) => {
    return cart.findIndex((searchItem) => {
        if (item?.promo) {
            if (searchItem.item.promo && item.pk == searchItem.item.pk && item?.promo?.id == searchItem?.item?.promo?.id) {
                return searchItem;
            }
        } else {
            if (item.pk == searchItem.item.pk && !searchItem.item.promo) {
                return searchItem;
            }
        }
    });
}