import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./PromosPage.sass";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import RfidModal from "../../../components/RfidModal/RfidModal";
import PromoItemsModal from "./../../../components/PromoItemsModal/PromoItemsModal";
import ItemButton from "./../../../components/ItemButton/ItemButton";
import { promoTypes } from "./../../../utils/promoTypes";
import { getCartItemIndex } from "../../../utils/getCartItemIndex";

const PromosPage = ({
  onItemAdd = () => {},
  cartData = [],
  currentPromo = {},
  setCurrentPromo = () => {},
  updateCart = () => {},
  customer = {},
  promos = [],
  isPromoDisabled = () => {},
}) => {
  const [showPromoItemsModal, setShowPromoItemsModal] = useState(null);

  const handlePromoClick = (
    id,
    userPromotion,
    type,
    maxUses,
    title,
    items,
    discountPercent
  ) => {
    if (!currentPromo[id]) {
      setCurrentPromo((current) => {
        let allPromos = { ...current };
        allPromos[id] = {
          title,
          items,
          maxUses,
          type,
          promo: id,
          used: 0,
          discountPercent,
          userPromotion,
        };
        return allPromos;
      });
    }
    setShowPromoItemsModal(id);
  };

  const handlePromoItemAdd = (item, quantity) => {
    let type = item.promo.type;
    let promo = promos.find((current) => current.id == item.promo.id);
    switch (promoTypes[type]) {
      case "promo_code":
        item.isSpecial = true;
        onItemAdd(item, quantity);
        break;
      case "buy_x_get_y":
        let total = +promo.buyX + +promo.getY;
        let current = 0;
        let cartCopy = [...cartData];
        for (let i = 0; i < cartCopy.length; i++) {
          if (cartCopy[i]?.item?.promo?.id == item.promo.id) {
            current += cartCopy[i].quantity;
          }
        }
        if (current == total - 1) {
          setShowPromoItemsModal(null);
          setCurrentPromo((current) => {
            let allPromos = { ...current };
            let updatedPromo = { ...current[item.promo.id] };
            updatedPromo.used += quantity;
            if (updatedPromo.used == 0) {
              delete allPromos[item.promo.id];
            } else {
              allPromos[item.promo.id] = updatedPromo;
            }
            return allPromos;
          });
          let freeItems = [];
          let cartItem = null;
          let cartItemIndex = getCartItemIndex(cartCopy, item);
          if (cartItemIndex != -1) {
            cartItem = { ...cartCopy[cartItemIndex] };
          }
          if (cartItem !== null) {
            cartItem.quantity += quantity;
            cartCopy[cartItemIndex] = cartItem;
          } else {
            cartCopy.push({ item, quantity });
          }
          let filteredCart = cartCopy.filter(
            (searchItem) => searchItem?.item?.promo?.id === item.promo.id
          );
          for (let i = 0; i < +promo.getY; i++) {
            let min = filteredCart.reduce((prev, curr) =>
              prev.item.price < curr.item.price ? prev : curr
            );
            let reducedIndex = getCartItemIndex(cartCopy, min.item);
            cartCopy[reducedIndex].quantity -= 1;
            if (cartCopy[reducedIndex].quantity === 0) {
              cartCopy.splice(reducedIndex, 1);
            }
            min = {
              item: { ...min.item, isSpecial: true, price: 0 },
              quantity: 1,
            };
            let promoItemIndex = getCartItemIndex(freeItems, min.item);
            if (promoItemIndex != -1) {
              freeItems[promoItemIndex].quantity += 1;
            } else {
              freeItems.push(min);
            }
          }
          cartCopy = cartCopy.concat(freeItems);
          updateCart(cartCopy);
        } else {
          onItemAdd({ ...item, isSpecial: false }, quantity);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="promosPage h-100">
      <Card className="h-100">
        <Card.Body className="h-100">
          <Row className="h-100">
            <Col xs={12}>
              {Object.keys(customer).length !== 0 && (
                <React.Fragment>
                  <div className="promoUser">
                    {customer.firstName + " " + customer.lastName}
                  </div>
                </React.Fragment>
              )}

              {/* <Button
                variant="danger"
                onClick={() => {
                  setCustomer({});
                }}
                className="ml-4"
                onFocus={(e) => e.target.blur()}
                autoFocus={false}
              >
                New Customer
              </Button> */}
              <Row className="mt-5">
                {promos.map((item) => (
                  <Col
                    className="mb-5"
                    xs={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={item.pk}
                  >
                    <ItemButton
                      item={{
                        name: item.name,
                        price: null,
                        picture: item.picture,
                      }}
                      onClick={() =>
                        handlePromoClick(
                          item.id,
                          item.userPromotion,
                          item.type,
                          promoTypes[item.type] === "buy_x_get_y"
                            ? +item.buyX + +item.getY
                            : item.maxUses,
                          item.name,
                          item.items,
                          item.discountPercent
                        )
                      }
                      withPicture={true}
                      isDisabled={isPromoDisabled(item.id)}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <PromoItemsModal
        title={currentPromo[showPromoItemsModal]?.title || ""}
        show={showPromoItemsModal !== null}
        onHide={() => {
          if (currentPromo[showPromoItemsModal]?.used == 0) {
            setCurrentPromo((current) => {
              let allPromos = { ...current };
              delete allPromos[showPromoItemsModal];
              return allPromos;
            });
          }
          setShowPromoItemsModal(null);
        }}
        items={currentPromo[showPromoItemsModal]?.items || []}
        promo={currentPromo[showPromoItemsModal] || null}
        onItemAdd={handlePromoItemAdd}
        cartData={cartData}
        isPromoDisabled={isPromoDisabled}
      />
    </div>
  );
};

export default PromosPage;
