import React from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemButton from "../../../../components/ItemButton/ItemButton";

export default function Menus({ menus, onItemClick = () => {} }) {
  return (
    <Card>
      <Card.Body>
        {menus?.map((menu) => (
          <Card key={menu.pk}>
            <Card.Body className="bg-dark">
              <Row>
                <Col xs={12}>
                  <h2 className="text-center text-light">{menu.name}</h2>
                </Col>
                {menu.items.map((item) => (
                  <Col
                    className="mb-4"
                    xs={6}
                    md={6}
                    lg={6}
                    xl={4}
                    key={item.pk}
                  >
                    <ItemButton
                      item={{
                        ...item,
                        price: item.discountedPrice,
                      }}
                      onClick={() =>
                        onItemClick({
                          ...item,
                          promo: item?.appliedPromo
                            ? {
                                id: item.appliedPromo.id,
                                type: item.appliedPromo.type,
                                name: item.appliedPromo.name,
                                discountPercent:
                                  item.appliedPromo.discountPercent,
                              }
                            : null,
                          isSpecial: item?.appliedPromo ? true : false,
                          oldPrice: item.price,
                          price: item.discountedPrice,
                        })
                      }
                      withPicture={true}
                    />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Card.Body>
    </Card>
  );
}
