import React from "react";
import { useHistory } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import LoginForm from "../../components/forms/LoginForm/LoginForm";
import LoginByRfidForm from "../../components/forms/LoginByRfidForm/LoginByRfidForm";

import "./LoginPage.sass";
import { useEffect } from "react";

export default function LoginPage({ currentUser, onSuccess }) {
  const history = useHistory();

  const onLoginSuccess = (user) => {
    if (user.isTeller) {
      history.push("/tellers");
    } else if (user.isVendor) {
      history.push("/vendors");
    } else if (user.isAccessControl) {
      history.push("/access-control");
    }
    onSuccess(user);
  };

  useEffect(() => {
    if (currentUser) {
      onLoginSuccess(currentUser);
    }
  }, [currentUser]);

  return (
    <Row className="LoginPage">
      <Col className="h-100" xs={12}>
        <Card className="h-100 mt-4">
          <Card.Body>
            <Card.Title className="text-center">
              <h1>Login</h1>
            </Card.Title>

            <Row>
              <Col className="mx-auto" xs={10}>
                <Row>
                  <Col xs={12}>
                    <h2>By Email</h2>
                  </Col>
                </Row>

                <LoginForm onSuccess={onLoginSuccess} />
              </Col>
            </Row>

            <Row className="mt-4 mb-5">
              <Col className="mx-auto" xs={10}>
                <Row>
                  <Col xs={12}>
                    <h2>By RFID</h2>
                  </Col>
                </Row>

                <LoginByRfidForm onSuccess={onLoginSuccess} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
