import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function entryAccessByPK(pk, party) {
    const { data } = await axios.get(routes.entryAccessByPK, {
        ...AXIOS_DEFAULT_CONFIG,
        params: {
            customer_pk: pk,
            party: party
        },
    });

    return data;
}