import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

export default async function ticketTiersByEvent(event_pk) {
  const { data } = await axios.get(routes.ticketTiersByEvent(event_pk), {
    ...AXIOS_DEFAULT_CONFIG,
  });
  return data;
}
