import React, { useState, useEffect } from "react";

import noImage from "../../assets/images/no-image.jpg";
import "./Avatar.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import * as api from "./../../api";

const Avatar = ({
  src,
  width = 50,
  editable = false,
  editCallback = () => {},
  isUploading = false,
  setIsUploading = () => {},
  customer = "",
}) => {
  const [newImage, setNewImage] = useState("");

  const handleImageChange = (event) => {
    setIsUploading(true);
    getBase64(event.target.files[0], (result) => {
      handleImageUpload(result);
    });
    event.target.value = null;
  };

  const getBase64 = (image, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = () => {
      setNewImage(src);
      setIsUploading(false);
    };
  };

  const handleImageUpload = (image) => {
    api.customers
      .changeProfilePicture(image, customer)
      .then((response) => {
        if (
          response.status &&
          response.status >= 200 &&
          response.status < 300
        ) {
          setIsUploading(false);
          setNewImage(response.data.profilePicture);
          editCallback(response.data.profilePicture);
        }
      })
      .catch(() => {
        setIsUploading(false);
        setNewImage(src);
        editCallback(src);
      });
  };

  return (
    <div
      className={"Avatar mx-auto" + (isUploading == true ? " uploading" : "")}
      style={{
        width,
        height: width,
        backgroundImage: `url(${
          newImage !== "" ? newImage : src ? src : noImage
        })`,
      }}
    >
      {editable && (
        <label htmlFor="editAvatar" className="avatar-edit">
          <input
            type="file"
            accept="image/*"
            id="editAvatar"
            name="editAvatar"
            className="Avatar-input"
            onChange={(event) => handleImageChange(event)}
          />
          <div className="avatar-edit-icon">
            <FontAwesomeIcon icon={faPen} />
          </div>
        </label>
      )}
    </div>
  );
};

export default Avatar;
