import React, { createRef, useEffect, useState, useRef } from "react";

import Modal from "react-bootstrap/Modal";
import Loader from "../Loader/Loader";
import NfcButton from "../NfcButton/NfcButton";

export default function RfidModal({
  show = false,
  onHide = () => {},
  onExited = () => {},
  successCustomer = null,
  successMessage = "Successful!",
  errorMessage = null,
  onSubmit = () => {},
  hideBalance = false,
  title = "Scan RFID",
  ignoreFfsScan = true,
  socket = null,
  showNfcButton = false,
}) {
  const rfidInput = createRef();
  const [nfcReadingEnabled, setNfcReadingEnabled] = useState(false);
  const [rfid, setRfid] = useState("");
  const [loading, setLoading] = useState(false);
  const rfidTimeout = useRef(null);

  const handleChange = (value) => {
    setRfid(value);
    if (value.length === 10) {
      setLoading(true);
      onSubmit(value);
      setRfid("");
    } else if (value.length > 10) {
      setRfid("");
    } else {
      handleRfidReset();
    }
  };

  const handleRfidReset = () => {
    if (rfid.length === 0) {
      if (rfidTimeout.current) {
        clearTimeout(rfidTimeout.current);
      }
    } else {
      if (rfidTimeout.current === null) {
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 1000);
      } else {
        clearTimeout(rfidTimeout.current);
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (
      show === true &&
      ignoreFfsScan === false &&
      socket !== null &&
      socket.readyState == 1
    ) {
      socket.addEventListener("message", listenForFfsScan);
    }
    return () => {
      if (socket !== null) {
        socket.removeEventListener("message", listenForFfsScan);
      }
    };
  }, [show, ignoreFfsScan, socket, successCustomer, loading, errorMessage]);

  const listenForFfsScan = (event) => {
    if ((successCustomer != null && !errorMessage) || loading) return;
    let data = JSON.parse(event?.data);
    if (data?.type === "rfid") {
      handleChange(data?.message);
    }
  };

  useEffect(() => {
    if (successCustomer !== null || errorMessage !== null) {
      setLoading(false);
    }
  }, [successCustomer, errorMessage]);

  useEffect(() => {
    window.addEventListener("keypress", detectEnterKey);
    return () => {
      window.removeEventListener("keypress", detectEnterKey);
    };
  }, []);

  const detectEnterKey = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  };

  const handleModalExit = () => {
    setLoading(false);
    setNfcReadingEnabled(false);
    onExited();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      onExited={handleModalExit}
      onShow={() => {
        if (rfidInput.current !== null) {
          rfidInput.current.focus();
        }
      }}
      className="RfidModal"
    >
      <Modal.Body className="text-center">
        {!successCustomer && !loading && (
          <>
            <h1>{title}</h1>
            <input
              ref={rfidInput}
              className="form-control"
              style={{ opacity: 0 }}
              type="text"
              name="rfid"
              id="rfid"
              value={rfid}
              onChange={(event) => handleChange(event.target.value)}
              onBlur={() => {
                if (rfidInput.current !== null) {
                  rfidInput.current.focus();
                }
              }}
              inputMode="none"
              autoComplete="off"
              autoFocus
            />
            {/* show && (
              <NfcButton
                nfcReadingEnabled={nfcReadingEnabled}
                setNfcReadingEnabled={setNfcReadingEnabled}
                handleChange={handleChange}
              />
            ) */}
          </>
        )}

        {successCustomer && (
          <p className="text-success" style={{ fontSize: 32 }}>
            {successMessage} <br />
            {!hideBalance && (
              <>
                {successCustomer.firstName} {successCustomer.lastName}'s balance
                is now
                <br />
                <span style={{ fontSize: 50 }}>
                  {Math.floor(successCustomer.balance / 100)} EGP
                </span>
              </>
            )}
          </p>
        )}

        {errorMessage && (
          <p className="text-danger" style={{ fontSize: 32 }}>
            {errorMessage} <br />
          </p>
        )}
        {loading && <Loader />}
      </Modal.Body>
    </Modal>
  );
}
