import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';

export default async function list(search = null, global = null) {
  const { data } = await axios.get(routes.customersIndex, {
    ...AXIOS_DEFAULT_CONFIG,
    params: {
      search,
      global
    },
  });
  return data;
}
