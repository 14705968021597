import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import MenusPage from "./MenusPage/MenusPage";
import OrdersPage from "./OrdersPage/OrdersPage";

import * as api from "../../api";

import "./Vendors.sass";

import { routes } from "./../../constants";

export default function Vendor({ currentUser }) {
  const { url, isExact } = useRouteMatch();
  const { pathname } = useLocation();
  const [rfid, setRfid] = useState("");
  const [customer, setCustomer] = useState({});
  const [balance, setBalance] = useState("");
  const [globalScanDisabled, setGlobalScanDisabled] = useState(false);
  const [promos, setPromos] = useState([]);
  const [socket, setSocket] = useState(null);

  const rfidTimeout = useRef(null);
  const balanceTimeout = useRef(null);
  const healthCheckTimer = useRef(null);
  const menusPath = `${url}/menus`;
  const ordersPath = `${url}/orders`;

  useEffect(() => {
    connectToSocket();
  }, []);

  useEffect(() => {
    handleSocketConnectionStatus();
    handleSocketMessages();
    return () => {
      if (socket !== null) {
        socket.removeEventListener("message", handleSocket);
      }
    };
  }, [socket, globalScanDisabled]);

  const connectToSocket = () => {
    let newsocket = new WebSocket(routes.vendorSocket(currentUser.vendorId));
    newsocket.onopen = () => {
      setSocket(newsocket);
    };
  };

  const handleSocketConnectionStatus = () => {
    if (healthCheckTimer.current) {
      clearInterval(healthCheckTimer.current);
    }
    healthCheckTimer.current = setInterval(() => {
      disconnectSocket();
      connectToSocket();
    }, 30000);
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.close();
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    handleRfidReset();
    handleRfidSubmit();
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [rfid, globalScanDisabled]);

  const handleSocketMessages = () => {
    if (socket) {
      socket.addEventListener("message", handleSocket);
    }
  };

  const handleKeyPress = (e) => {
    if (globalScanDisabled) {
      return;
    }
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      setRfid((current) => {
        return current + e.key;
      });
    }
  };

  const handleRfidSubmit = () => {
    if (rfid.length === 10) {
      let customerRfid = rfid;
      getCustomerAndBalance(customerRfid);
      getPromotions(customerRfid);
    }
  };

  const handleRfidReset = () => {
    if (rfid.length === 0) {
      if (rfidTimeout.current) {
        clearTimeout(rfidTimeout.current);
      }
    } else {
      if (rfidTimeout.current === null) {
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 250);
      } else {
        clearTimeout(rfidTimeout.current);
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 250);
      }
    }
  };

  const getCustomerAndBalance = (customerRfid) => {
    if (globalScanDisabled) {
      return;
    }
    api.customers.byRfid(customerRfid).then((data) => {
      setCustomer(data);
      setBalance(data.balance / 100 + " EGP");
      if (balanceTimeout.current !== null) {
        clearTimeout(balanceTimeout.current);
      }
      balanceTimeout.current = setTimeout(() => {
        setBalance("");
        sendCartUpdateToFfs();
      }, 30000);
    });
  };

  const getPromotions = (customerRfid) => {
    api.promos.list(customerRfid).then((data) => {
      let fetchedPromos = data.map((item) => ({
        ...item.promo,
        userPromotion: item.id,
      }));
      setPromos(fetchedPromos);
    });
  };

  useEffect(() => {
    if (socket && socket?.readyState == 1) {
      socket.send(
        JSON.stringify({
          type: "globalScan",
          message: {
            balance,
            promos,
          },
        })
      );
    }
  }, [balance, promos]);

  const handleSocket = (event) => {
    let data = JSON.parse(event?.data);
    if (data?.type === "fetch") {
      sendCartUpdateToFfs();
    } else if (data?.type === "rfid") {
      if (!globalScanDisabled) {
        setRfid(data.message);
      }
    }
  };

  const sendCartUpdateToFfs = (type = "change") => {
    let storedCartData = JSON.parse(localStorage.getItem("cashlessCart")) || [];
    if (socket !== null && socket?.readyState == 1) {
      socket.send(
        JSON.stringify({
          type,
          message: {
            items: storedCartData,
            total:
              (storedCartData?.reduce(
                (acc, { item, quantity }) => item.price * quantity + acc,
                0
              ) ?? 0) / 100,
          },
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Row className="Vendors">
        {isExact && <Redirect to={menusPath} />}

        <Col xs={12}>
          <Nav justify variant="pills" defaultActiveKey="menus">
            <Nav.Item>
              <Nav.Link
                eventKey="menus"
                as={Link}
                active={pathname === menusPath}
                to={menusPath}
                onFocus={() => (e) => e.target.blur()}
              >
                Menus
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                eventKey="orders"
                as={Link}
                active={pathname === ordersPath}
                to={ordersPath}
                onFocus={(e) => e.target.blur()}
              >
                Orders
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>

        <Col style={{ height: "calc(100% - 40px)" }} xs={12}>
          <Switch>
            <Route exact path={menusPath}>
              <MenusPage
                setGlobalScanDisabled={setGlobalScanDisabled}
                balance={balance}
                socket={socket}
                promos={promos}
                customer={customer}
                setCustomer={setCustomer}
                setPromos={setPromos}
                sendCartUpdateToFfs={sendCartUpdateToFfs}
              />
            </Route>

            <Route exact path={ordersPath}>
              <OrdersPage
                currentUser={currentUser}
                setGlobalScanDisabled={setGlobalScanDisabled}
                socket={socket}
              />
            </Route>
          </Switch>
        </Col>
      </Row>
      {currentUser.vendorId && (
        <p className="vendor-id">{currentUser.vendorId}</p>
      )}
    </React.Fragment>
  );
}
