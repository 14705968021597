import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function byCustomer(customerPk, limit = null) {
  const { data } = await axios.get(routes.customerCashTransactions(customerPk), {
    ...AXIOS_DEFAULT_CONFIG,
    params: {
      limit,
    }
  });
  return data;
}
