import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import * as api from "../../../api";
import { DEFAULT_DEBOUNCE_TIME } from "../../../constants";
import CustomerModal from "../../../components/CustomerModal/CustomerModal";
import DataTable from "../../../components/DataTable/DataTable";
import SearchField from "../../../components/fields/SearchField/SearchField";
import UserForm from "../../../components/forms/UserForm/UserForm";

import "./UsersPage.sass";

export default function UsersPage({ party, event, rfidSettings }) {
  const [customers, setCustomers] = useState(null);
  const [detailCustomer, setDetailCustomer] = useState(null);
  const [detailCustomerCashTransactions, setDetailCustomerCashTransactions] =
    useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerAccess, setCustomerAccess] = useState(null);
  const [isStaff, setIsStaff] = useState(null);
  const [isCheckedIn, setIsCheckedIn] = useState(null);
  const [error, setError] = useState("");
  const [focusSearchFlag, setFocusSearchFlag] = useState(false);
  const [tab, setTab] = useState("allCustomers");
  const [isActivated, setIsActivated] = useState(null);

  const onQueryChange = useDebouncedCallback((query) => {
    if (query === "") query = null;
    api.customers.list(query, true).then(generateResponseHandler(true));
  }, DEFAULT_DEBOUNCE_TIME);

  const generateResponseHandler = (reset) => (response) => {
    setCustomerCount(response.count);
    let newCustomers;
    if (reset) {
      newCustomers = response.results;
    } else {
      newCustomers = [...customers, ...response.results];
    }
    setCustomers(newCustomers);
    setNextPage(response.next);
  };

  const handleCustomerClick = (customer) => {
    setShowCustomerModal(true);
    api.entryAccess
      .entryAccessByPK(customer.pk, party.pk)
      .then((data) => {
        let user = data.user;
        let staff = false;
        let loadedCustomer = data.customer;
        loadedCustomer.tickets = data.tickets;
        if (
          user.isAccessControl ||
          user.isOutletManager ||
          user.isTeller ||
          user.isVendor ||
          user.isStaff ||
          user.isNcStaff
        ) {
          staff = true;
        }
        setIsActivated(data.isActivated);
        setIsStaff(staff);
        setIsCheckedIn(data.isCheckedIn);
        setCustomerAccess(staff ? true : data.hasAccess);
        setDetailCustomer(loadedCustomer);
        api.cashTransactions
          .byCustomer(customer.pk)
          .then((response) =>
            setDetailCustomerCashTransactions(response.results)
          );
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.detail) {
          setError(err.response.data.detail);
        } else {
          setError("Something went wrong");
        }
      });
  };

  const handleCustomerModalExit = () => {
    setDetailCustomer(null);
    setError("");
    setFocusSearchFlag(true);
  };

  useEffect(() => {
    if (!customers) {
      api.customers.list(null, true).then(generateResponseHandler(true));
    }
  }, [customers]);

  useEffect(() => {
    if (!tab) return;
    if (tab === "allCustomers") {
      setFocusSearchFlag(true);
    }
  }, [tab]);

  let requestAlreadySent = false;
  const onBottomReached = () => {
    if (requestAlreadySent || !nextPage) return;

    requestAlreadySent = true;
    api.genericGet(nextPage).then(generateResponseHandler(false));
  };

  return (
    <Row className="UsersPage">
      <Col className="h-100" xs={12}>
        <Card className="h-100">
          <Card.Body className="h-100">
            <Card.Title className="text-left">
              <h1>Customers</h1>
            </Card.Title>

            <Row className="mb-4">
              <Col xs={12}>
                <Tab.Container defaultActiveKey="allCustomers">
                  <Row>
                    <Col className="mx-auto" xs={12}>
                      <Nav justify variant="pill">
                        <Nav.Item onClick={() => setTab("allCustomers")}>
                          <Nav.Link eventKey="allCustomers">
                            All Customers
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item onClick={() => setTab("newCustomer")}>
                          <Nav.Link eventKey="newCustomer">
                            New Customer
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col className="mx-auto" xs={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="allCustomers">
                          <Row>
                            <Col className="mx-auto" xs={12}>
                              <SearchField
                                onQueryChange={onQueryChange}
                                focusFlag={focusSearchFlag}
                                setFocusFlag={setFocusSearchFlag}
                                showNfcButton={true}
                              />
                            </Col>
                          </Row>

                          <Row className="mt-4 table-container">
                            <Col className="h-100 mx-auto" xs={12}>
                              <DataTable
                                config={{
                                  keyField: "pk",
                                  mapper: [
                                    {
                                      title: "Name",
                                      render: (customer) =>
                                        `${customer.firstName} ${customer.lastName}`,
                                      onClick: handleCustomerClick,
                                    },
                                    {
                                      title: "Email",
                                      dataKey: "email",
                                    },
                                    {
                                      title: "Mobile Number",
                                      dataKey: "mobileNumber",
                                    },
                                  ],
                                }}
                                data={customers}
                                dataCount={customerCount}
                                onBottomReached={onBottomReached}
                                showCount
                                dataType={"customers"}
                              />
                            </Col>
                          </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="newCustomer">
                          <UserForm party={party} event={event} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <CustomerModal
        show={showCustomerModal}
        onExited={handleCustomerModalExit}
        onHide={() => setShowCustomerModal(false)}
        customer={detailCustomer}
        tickets={detailCustomer?.tickets}
        setCustomer={setDetailCustomer}
        cashTransactions={detailCustomerCashTransactions}
        customerAccess={customerAccess}
        isStaff={isStaff}
        isCheckedIn={isCheckedIn}
        setIsCheckedIn={setIsCheckedIn}
        party={party}
        customerLoadError={error}
        isActivated={isActivated}
        showNfcButton={true}
        rfidSettings={rfidSettings}
      />
    </Row>
  );
}
