import React, { useEffect, useRef } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import "./toast.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CustomToast = ({
  show = false,
  onClose = () => {},
  header = "",
  body = "",
  handleToastClick = () => {},
  timeout = 30,
}) => {
  const timeOutRef = useRef(null);
  useEffect(() => {
    if (timeOutRef.current !== null) {
      clearTimeout(timeOutRef.current);
    }
    if (show) {
      timeOutRef.current = setTimeout(() => {
        onClose();
      }, timeout * 1000);
    }
  }, [show]);
  return (
    <ToastContainer
      className="custom-toast-container p-3"
      onClick={handleToastClick}
    >
      <Toast show={show} className="custom-toast">
        <Toast.Header closeButton={false}>
          {header && <strong className="me-auto">{header}</strong>}
          <FontAwesomeIcon
            icon={faTimes}
            className="custom-toast-close"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            size={"lg"}
          />
        </Toast.Header>
        {body && <Toast.Body>{body}</Toast.Body>}
      </Toast>
    </ToastContainer>
  );
};

export default CustomToast;
