import React from "react";

import "./LogoutModal.sass";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function LogoutModal({
  show = false,
  onHide = () => {},
  handleLogout = () => {},
  error = "",
}) {
  return (
    <Modal
      className="LogoutModal"
      centered
      size="md"
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton={false}>
        Logout <FontAwesomeIcon icon={faTimes} size={"lg"} onClick={onHide} />
      </Modal.Header>

      <Modal.Body>
        {error && <p className="error">{error}</p>}
        <p>Do you want to?</p>
        <Button
          variant="primary"
          className="btn-primary mr-3"
          onClick={() => handleLogout(true)}
        >
          Logout and end shift
        </Button>
        <Button variant="danger" onClick={() => handleLogout(false)}>
          Logout only
        </Button>
      </Modal.Body>
    </Modal>
  );
}
