import React from "react";
import { Row, Col } from "react-bootstrap";
import Avatar from "../../Avatar/Avatar";

import "./customerInformationTab.sass";

const CustomerInformationTab = ({
  customer = null,
  tickets = [],
  partyAccess = null,
  accessConflict = null,
  isStaff = null,
}) => {
  return (
    <Row className="customer-info-tab">
      <Col xs={12} md={8}>
        <Row>
          <Col xs={12}>
            <h1 className="text-center">
              {customer.firstName} {customer.lastName}
            </h1>
          </Col>

          <Col xs={12}>
            <Avatar src={customer.profilePicture} width={250} />
          </Col>

          <Col className="my-4" style={{ fontSize: 22 }} xs={12} lg={6}>
            <Row>
              {partyAccess !== null && (
                <React.Fragment>
                  <Col className="mt-2" xs={12}>
                    {partyAccess ? (
                      <div className="access-check">
                        <h2 className="text-success mb-0 access-check-text">
                          Allowed
                        </h2>
                        <div className="access-check-color access-check-color--success"></div>
                      </div>
                    ) : (
                      <div className="access-check">
                        <h2 className="text-danger mb-0 access-check-text">
                          Denied
                        </h2>
                        <div className="access-check-color access-check-color--danger"></div>
                      </div>
                    )}
                  </Col>
                  {isStaff && (
                    <Col xs={12}>
                      <div className="staff-flag">STAFF</div>
                    </Col>
                  )}
                </React.Fragment>
              )}
              <Col className="mt-0" xs={12}>
                Email: {customer.email}
              </Col>
              <Col className="mt-2" xs={12}>
                Mobile number: {customer.mobileNumber}
              </Col>
              {customer.balance !== undefined && (
                <Col className="mt-2" xs={12}>
                  Balance: {customer.balance / 100} EGP
                </Col>
              )}
              {tickets.map((ticket, index) => (
                <React.Fragment key={index}>
                  <h4 className="mt-2 pl-3 mb-0">Ticket #{index + 1}</h4>
                  {ticket.tier && (
                    <Col className="mt-2 pl-5" xs={12}>
                      Ticket Type: {ticket.tier.name} {ticket.tier.nameLine2}{" "}
                      {ticket.tier.nameLine3}
                    </Col>
                  )}
                  {ticket.reservation && (
                    <React.Fragment>
                      <Col className="mt-2 pl-5" xs={12}>
                        Reservation Code: {ticket.reservation.code}
                      </Col>
                      <Col className="mt-2 pl-5" xs={12}>
                        Reservation Head:{" "}
                        {ticket.reservation.reservationHead.firstName}{" "}
                        {ticket.reservation.reservationHead.lastName}
                      </Col>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={4}>
        {partyAccess === true && accessConflict === false ? (
          <div className="gate-access-color-code gate-access-color-code--success"></div>
        ) : (
          <div className="gate-access-color-code gate-access-color-code--fail"></div>
        )}
      </Col>
    </Row>
  );
};

export default CustomerInformationTab;
