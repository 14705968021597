import React from "react";
import "./Autocomplete.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";

const Autocomplete = ({
  list = [],
  selected = {},
  onSelect = () => {},
  expanded = false,
  onExpand = () => {},
  onClear = () => {},
  showCount = false,
}) => {
  return (
    <React.Fragment>
      {expanded && list?.length > 0 && (
        <div className="autocomplete">
          {list.map((item, index) => (
            <div
              key={index}
              className={
                "autocomplete__item" +
                (selected?.id == item?.id
                  ? " autocomplete__item--selected"
                  : "")
              }
              onClick={() => onSelect(item)}
            >
              {item?.label}
            </div>
          ))}
        </div>
      )}
      {list?.length > 0 && (
        <div
          className="autocomplete__icon-container"
          onClick={(e) => {
            e.stopPropagation();
            onExpand((current) => !current);
          }}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className={
              "autocomplete__dropdown" +
              (expanded ? " autocomplete__dropdown--expanded" : "")
            }
          />
        </div>
      )}
      {Object.keys(selected)?.length != 0 && (
        <div
          className="autocomplete__icon-container autocomplete__clear-container"
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="autocomplete__clear-icon"
          />
        </div>
      )}
      {showCount && list?.length > 0 && (
        <div className="autocomplete__count">{list?.length} results found</div>
      )}
    </React.Fragment>
  );
};

export default Autocomplete;
