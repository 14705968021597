import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function login(email, password) {
  const { data } = await axios.post(routes.login, {
    email,
    password,
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
