import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as api from "../../../api";

import "./LoginForm.sass";

export default function LoginForm({ onSuccess = () => {} }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [backendErrors, setBackendErrors] = useState({});

  const onSubmit = ({ email, password }) => {
    api.auth
      .login(email, password)
      .then((response) => {
        onSuccess(response);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setBackendErrors(error.response.data);
        } else if (error.response && error.response.status === 403) {
          setBackendErrors({ nonFieldErrors: [error.response.data.detail] });
        } else {
          setBackendErrors("Something went wrong");
        }
      });
  };

  return (
    <Row className="LoginForm">
      <Col xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              {backendErrors?.nonFieldErrors?.map((error) => (
                <span key={`nonFieldErrors-${error}`} className="d-block error">
                  {error}
                </span>
              ))}
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <label htmlFor="email">Email</label>

              {errors.email && (
                <span className="d-block error float-right">
                  Email is required
                </span>
              )}
              <input
                {...register("email", { required: true })}
                className="form-control"
                type="email"
                name="email"
                id="email"
              />
            </Col>

            <Col xs={12} md={6}>
              <label htmlFor="password">Password</label>

              {errors.password && (
                <span className="d-block error float-right">
                  Password is required
                </span>
              )}
              <input
                {...register("password", { required: true })}
                className="form-control"
                type="password"
                name="password"
                id="password"
              />
            </Col>

            <Col className="mt-4" xs={12} md={{ span: 6, offset: 6 }}>
              <input
                type="submit"
                value="Login"
                className="btn btn-primary form-control"
              />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
}
