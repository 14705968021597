import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import './DataTable.sass';


export default function DataTable(
  {
    config,
    data,
    dataCount = 0,
    onBottomReached = () => {},
    showCount = false,
    footerRows = [],
    noScroll = false,
    dataType = 'rows',
  }
) {
  const handleTableScroll = (event) => {
    const bottomReached = (event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight;
    if(bottomReached) onBottomReached();
  };

  return (
    <Row className="DataTable h-100">
      <Col className="h-100" xs={12}>
        {showCount && data && <span className="text-muted">Showing {data.length} of {dataCount} {dataType}</span>}

        <div className={!noScroll ? 'datatable-container' : ''} onScroll={handleTableScroll}>
          <Table striped bordered>
            <thead>
              <tr>
                {config.mapper.map(({ title }) => (
                  <th key={title}>{title}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data?.map((entry) => (
                <tr key={entry[config.keyField]}>
                  {config.mapper.map(({ title, dataKey, render, onClick, clickable }) => (
                    <td
                      className={onClick && (!clickable || clickable(entry)) ? 'clickable' : ''}
                      key={`${entry[config.keyField]}-${title}`}
                      onClick={onClick ? (() => onClick(entry)) : (() => {})}
                    >
                      {
                        (dataKey && entry[dataKey]) ||
                        (render && render(entry))
                      }
                    </td>
                  ))}
                </tr>
              ))}

              {footerRows?.map((entry, index) => (
                <tr key={index}>
                  {entry.map((data) => (
                    <th>
                      {data}
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
}
