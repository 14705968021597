import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function accessLogs(rfid = null, party, type, pk = null) {
    let body = {
        party, type
    }
    if (rfid) {
        body.rfid = rfid
    }
    if (pk) {
        body.pk = pk
    }
    const response = await axios.post(routes.accessLogs, {
        ...body
    }, {
        ...AXIOS_DEFAULT_CONFIG,

    });

    return response;
}