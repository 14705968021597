import React, { useState, useRef } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import * as api from "../../api";
import DataTable from "../DataTable/DataTable";
import RfidModal from "../RfidModal/RfidModal";

import "./OrderModal.sass";
import generateRandomToken from "../../utils/generateRandomToken";

export default function OrderModal({
  show = false,
  onHide = () => {},
  onExited = () => {},
  order = null,
  customer = null,
  currentUser = null,
  onRefundSuccess = () => {},
  showCustomerBtn = false,
  onShowCustomer = () => {},
}) {
  const [showRfidModal, setShowRfidModal] = useState(false);
  const [successCustomer, setSuccessCustomer] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(generateRandomToken());

  const refundBtn = useRef(null);
  const showBtn = useRef(null);

  const handleRfidSubmit = async (rfid) => {
    setErrorMessage(null);
    const loadedOrder = await api.orders
      .refund(order.pk, rfid, token)
      .catch((error) => {
        const data = error.response.data;
        const dataKeys = Object.keys(data);
        setErrorMessage(data[dataKeys[0]][0]);
      });

    if (loadedOrder) {
      setSuccessCustomer(loadedOrder.customer);
      setToken(generateRandomToken());
      onRefundSuccess(loadedOrder);
      setErrorMessage(null);
    }
  };

  const handleRfidExit = () => {
    setSuccessCustomer(null);
    setErrorMessage(null);
  };

  return (
    <Modal
      className="OrderModal"
      centered
      size="lg"
      show={show}
      onHide={onHide}
      onExited={onExited}
    >
      <Modal.Body>
        {order && customer && (
          <>
            <Row>
              <Col xs={12}>
                <h1 className="text-center">Order #{order.pk}</h1>
              </Col>
            </Row>

            <Row style={{ fontSize: 22 }}>
              <Col xs={12}>
                Customer: {customer.firstName} {customer.lastName}
              </Col>

              <Col xs={12}>
                Processed by:{" "}
                {(
                  order.creator.firstName +
                  " " +
                  order.creator.lastName
                ).trim() || order.creator.email}
              </Col>

              <Col xs={12}>Outlet: {order?.outlet?.name || ""}</Col>

              <Col className="mt-2" xs={12}>
                {!order.refundedAt &&
                  (currentUser?.isOutletManager ||
                    currentUser?.pk === order.creator.pk) && (
                    <Button
                      variant="danger"
                      onClick={() => setShowRfidModal(true)}
                      ref={refundBtn}
                      autoFocus={false}
                      onFocus={() => {
                        if (refundBtn && refundBtn.current) {
                          refundBtn.current.blur();
                        }
                      }}
                    >
                      Refund
                    </Button>
                  )}
                {order?.refundedAt && (
                  <span className="text-danger">Refunded</span>
                )}
                {showCustomerBtn && (
                  <Button
                    variant="primary"
                    onClick={() => onShowCustomer(order)}
                    className="ml-4"
                    ref={showBtn}
                    autoFocus={false}
                    onFocus={() => {
                      if (showBtn && showBtn.current) {
                        showBtn.current.blur();
                      }
                    }}
                  >
                    Show To Customer
                  </Button>
                )}
              </Col>
            </Row>

            <Row>
              <Col className="mt-4" xs={12}>
                <DataTable
                  config={{
                    keyField: "pk",
                    mapper: [
                      {
                        title: "Item",
                        dataKey: "name",
                      },
                      {
                        title: "Price",
                        render: (orderItem) =>
                          `${orderItem.priceAtPurchase / 100} EGP`,
                      },
                      {
                        title: "Quantity",
                        dataKey: "quantity",
                      },
                      {
                        title: "Sum",
                        render: (orderItem) =>
                          `${
                            (orderItem.priceAtPurchase * orderItem.quantity) /
                            100
                          } EGP`,
                      },
                    ],
                  }}
                  data={order.orderItems}
                  footerRows={[["Total", "", "", `${order.total / 100} EGP`]]}
                  noScroll
                />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>

      <RfidModal
        show={showRfidModal}
        onHide={() => setShowRfidModal(false)}
        onExited={() => handleRfidExit()}
        successMessage="Order refunded successfully"
        errorMessage={errorMessage}
        successCustomer={successCustomer}
        onSubmit={handleRfidSubmit}
        title="Scan Manager RFID"
      />
    </Modal>
  );
}
