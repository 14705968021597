import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RFIDField from "../../fields/RFIDField/RFIDField";
import CustomerInformationTab from "../customerInformationTab/customerInformationTab";
import * as api from "../../../api";

const GateAccessTab = ({ party = null }) => {
  const [entryTypes] = useState([
    { label: "In", value: "in" },
    { label: "Out", value: "out" },
  ]);
  const [selectedEntryType, setSelectedEntryType] = useState("");
  const [customer, setCustomer] = useState(null);
  const [accessConflict, setAccessConflict] = useState(null);
  const [partyAccess, setPartyAccess] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);
  const [active, setActive] = useState(true);
  const [isStaff, setIsStaff] = useState(null);
  const timeOutRef = useRef(null);

  const onRFIDSubmit = (rfid) => {
    setActive(false);
    api.entryAccess
      .accessLogs(rfid, party, selectedEntryType.value)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const { data } = response;
          let user = data.user;
          let staff = false;
          if (
            user.isAccessControl ||
            user.isOutletManager ||
            user.isTeller ||
            user.isVendor ||
            user.isStaff ||
            user.isNcStaff
          ) {
            staff = true;
          }
          setIsStaff(staff);
          setCustomer(data.customer);
          setAccessConflict(staff ? false : data.accessConflict);
          setPartyAccess(staff ? true : data.hasAccess);
          setTickets(data.tickets);
          setError(null);
          disableRFIDField();
        }
      })
      .catch((err) => {
        setCustomer(null);
        setAccessConflict(null);
        setTickets([]);
        setPartyAccess(null);
        if (err.response && err.response.status === 400) {
          setError("Invalid rfid");
          disableRFIDField();
        } else if (err.response && err.response.status === 406) {
          setError("USER IS BLACKLISTED!!!");
          disableRFIDField();
        }
      });
  };

  const disableRFIDField = () => {
    const timeout = setTimeout(() => setActive(true), 2000);
    timeOutRef.current = timeout;
  };

  useEffect(() => {
    const timeoutId = timeOutRef.current;
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <Row>
        <Col xs={6} md={3} lg={2}>
          <label htmlFor="entryTypes">Entry Type</label>
          <Select
            id="entryTypes"
            options={entryTypes}
            value={selectedEntryType}
            onChange={(selected) => setSelectedEntryType(selected)}
            isSearchable={false}
          />
        </Col>
      </Row>
      {selectedEntryType && (
        <React.Fragment>
          <Row className="mt-4">
            <Col xs={12}>
              <RFIDField
                onSubmit={onRFIDSubmit}
                active={active}
                showNfcButton={true}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              {error && <h2 className="text-danger">{error}</h2>}
              {customer && (
                <CustomerInformationTab
                  customer={customer}
                  tickets={tickets}
                  partyAccess={partyAccess}
                  accessConflict={accessConflict}
                  isStaff={isStaff}
                />
              )}
            </Col>
          </Row>
        </React.Fragment>
      )}
      {!selectedEntryType && <h3 className="text-danger">SELECT ENTRY TYPE</h3>}
    </>
  );
};

export default GateAccessTab;
