import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './Loader.sass';


export default function Loader() {
  return (
    <Row className="Loader">
      <Col>
        Loading...
      </Col>
    </Row>
  );
}
