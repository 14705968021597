import React, { useEffect, useState, useRef } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as api from "../../../api";
import Cart from "./Cart/Cart";
import Menus from "./Menus/Menus";
import Nav from "react-bootstrap/Nav";

import "./MenusPage.sass";
import { Tab } from "react-bootstrap";
import PromosPage from "./../PromosPage/PromosPage";
import { promoTypes } from "../../../utils/promoTypes";
import { getCartItemIndex } from "./../../../utils/getCartItemIndex";

export default function MenusPage({
  setGlobalScanDisabled = () => {},
  balance = "",
  socket = null,
  promos = [],
  customer = {},
  setCustomer = () => {},
  setPromos = () => {},
  sendCartUpdateToFfs = () => {},
}) {
  const [outlet, setOutlet] = useState(null);
  const [cartData, setCartData] = useState(null);
  const [currentPromo, setCurrentPromo] = useState({});

  const previousCustomerId = useRef(null);

  useEffect(() => {
    if (Object.keys(customer).length !== 0) {
      if (previousCustomerId.current) {
        if (customer.pk !== previousCustomerId.current) {
          setCurrentPromo({});
          updateCart([], false);
        }
      }
      previousCustomerId.current = customer.pk;
    }
  }, [customer]);

  useEffect(() => {
    if (cartData === null) {
      let storedCartData =
        JSON.parse(localStorage.getItem("cashlessCart")) || [];
      setCartData(storedCartData);
      sendCartUpdateToFfs();
    }
  }, [cartData]);

  const updateCart = (newCartData, clear = false) => {
    localStorage.setItem("cashlessCart", JSON.stringify(newCartData));
    if (newCartData.length === 0) {
      setCurrentPromo({});
    }
    if (socket !== null && socket.readyState == 1) {
      sendCartUpdateToFfs(
        newCartData.length === 0 && !clear ? "end" : "change"
      );
    }
    setCartData(newCartData);
  };

  const addItem = (item, quantity = 1) => {
    let newCartData = [...cartData];
    let cartItem = null;
    let cartItemIndex = getCartItemIndex(newCartData, item);
    if (cartItemIndex >= 0) {
      cartItem = { ...cartData[cartItemIndex] };
    }
    if (cartItem !== null) {
      cartItem.quantity += quantity;
      newCartData[cartItemIndex] = cartItem;
      if (newCartData[cartItemIndex].quantity === 0) {
        const index = newCartData.indexOf(cartItem);
        newCartData.splice(index, 1);
      }
    } else {
      newCartData.push({ item, quantity });
    }
    if (item?.promo) {
      setCurrentPromo((current) => {
        let allPromos = { ...current };
        let updatedPromo = { ...current[item.promo.id] };
        updatedPromo.used += quantity;
        if (updatedPromo.used == 0) {
          delete allPromos[item.promo.id];
        } else {
          allPromos[item.promo.id] = updatedPromo;
        }
        return allPromos;
      });
    }
    if (quantity <= 0 && promoTypes[item?.promo?.type] == "buy_x_get_y") {
      newCartData = newCartData.filter((item) => !item?.item?.promo);
    }
    updateCart(newCartData);
  };

  useEffect(() => {
    if (!outlet) {
      (async () => {
        const loadedOutlet = await api.outlets.assigned();
        setOutlet(loadedOutlet);
      })();
    }
  }, [outlet]);

  const removePromoItems = (id) => {
    let cartCopy = [...cartData];
    cartCopy = cartCopy.filter(
      (item) => !item?.item?.promo || item?.item?.promo?.id !== id
    );
    removePromo(id);
    updateCart(cartCopy, true);
  };

  const removePromo = (id) => {
    let allPromos = { ...currentPromo };
    delete allPromos[id];
    setCurrentPromo(allPromos);
  };

  const isPromoDisabled = (id) => {
    return currentPromo[id]?.maxUses <= currentPromo[id]?.used;
  };

  return (
    <Row className="MenusPage h-100">
      <Col className="h-100" xs={12}>
        <Card>
          <Card.Body>
            <Row className="h-100">
              <Col className="h-100 Menu" xs={8}>
                <Row className="title">
                  <Col xs={12}>
                    <h1 className="text-center">{outlet?.name}</h1>
                  </Col>
                </Row>
                <Tab.Container defaultActiveKey="items">
                  <Nav justify variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="items"
                        onFocus={() => (e) => e.target.blur()}
                      >
                        Items
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="promos"
                        onFocus={() => (e) => e.target.blur()}
                      >
                        Promos
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="items">
                      <Row className="menus">
                        <Col xs={12}>
                          <Menus menus={outlet?.menus} onItemClick={addItem} />
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="promos">
                      <Row className="menus">
                        <Col xs={12}>
                          <PromosPage
                            onItemAdd={addItem}
                            cartData={cartData}
                            setCartData={setCartData}
                            currentPromo={currentPromo}
                            setCurrentPromo={setCurrentPromo}
                            updateCart={updateCart}
                            promos={promos}
                            customer={customer}
                            isPromoDisabled={isPromoDisabled}
                          />
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>

              <Col className="h-100 border-left" xs={4}>
                <Cart
                  data={cartData}
                  isPromoDisabled={isPromoDisabled}
                  onClearClick={() => updateCart([], true)}
                  onBuySuccess={() => {
                    updateCart([], false);
                    setCustomer({});
                    setCurrentPromo({});
                    setPromos([]);
                  }}
                  onItemAdd={addItem}
                  setGlobalScanDisabled={setGlobalScanDisabled}
                  removePromoItems={removePromoItems}
                  balance={balance}
                  socket={socket}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
