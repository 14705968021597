import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function loginByRfid(rfid) {
  const { data } = await axios.post(routes.loginByRfid, {
    rfid,
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
