import React from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import TopUpOrCashOut from "./TopUpOrCashOut/TopUpOrCashOut";

import "./CashPage.sass";

export default function CashPage({ currentUser }) {
  const { isExact, url } = useRouteMatch();
  const { pathname } = useLocation();
  const topUpPath = `${url}/top-up`;
  const cashOutPath = `${url}/cash-out`;

  return (
    <Row className="CashPage">
      {isExact && <Redirect to={topUpPath} />}

      <Col className="h-100" xs={12}>
        <Card className="h-100 overflow-auto">
          <Card.Body>
            <Card.Title className="text-center">
              <Row>
                <Col xs={12}>
                  <h1>
                    {pathname === topUpPath && "Top up"}
                    {pathname === cashOutPath && "Cash out"}
                  </h1>
                </Col>
              </Row>

              <Row>
                <Col className="mx-auto" xs={10}>
                  <Nav justify variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        active={pathname === topUpPath}
                        to={topUpPath}
                      >
                        Top up
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        active={pathname === cashOutPath}
                        to={cashOutPath}
                      >
                        Cash out
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            </Card.Title>

            <Row>
              <Col className="mt-5 mb-4" xs={12}>
                <Switch>
                  <Route exact path={topUpPath}>
                    <TopUpOrCashOut type="topUp" currentUser={currentUser} />
                  </Route>

                  <Route exact path={cashOutPath}>
                    <TopUpOrCashOut type="cashOut" currentUser={currentUser} />
                  </Route>
                </Switch>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
