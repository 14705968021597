import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

export default async function changeProfilePicture(
    profilePicture, customer
) {
    const response = await axios.patch(
        routes.customersChangeProfilePicture,
        {
            profilePicture, customer
        },
        AXIOS_DEFAULT_CONFIG
    );

    return response;
}
