import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function byRfid(rfid) {
  const { data } = await axios.get(routes.customerByRfid, {
    ...AXIOS_DEFAULT_CONFIG,
    params: {
      rfid,
    },
  });

  return data;
}
