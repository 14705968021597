import React from "react";
import { Button } from "react-bootstrap";

import "./tierButton.sass";

const TierButton = ({
  onSelect = () => {},
  label = null,
  value = null,
  status = null,
  conflicts = null,
}) => {
  return (
    <button
      className={
        "tierButton" + (status === "selected" ? " tierButton--selected" : "")
      }
      onClick={() => onSelect(value, conflicts)}
      disabled={status === "disabled"}
    >
      {label}
    </button>
  );
};

export default TierButton;
