import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';

export default async function refund(pk, rfid, token) {
  const { data } = await axios.post(routes.orderRefund(pk), {
    rfid,
    token,
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
