import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function autoGenerate() {
  const { data } = await axios.get(routes.customerAutoGenerate, {
    ...AXIOS_DEFAULT_CONFIG,
  });

  return data;
}
