import React, { useEffect, useState, useRef } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import * as api from "../../../api";
import DataTable from "../../../components/DataTable/DataTable";
import OrderModal from "../../../components/OrderModal/OrderModal";
import getTimeString from "../../../utils/getTimeString";

import "./OrdersPage.sass";

export default function OrdersPage({
  currentUser,
  setGlobalScanDisabled = () => {},
  socket = null,
}) {
  const [orders, setOrders] = useState(null);
  const [ordersCount, setOrdersCount] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [modalOrder, setModalOrder] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [pageType, setPageType] = useState("myOrders");

  const navRef1 = useRef(null);
  const navRef2 = useRef(null);

  const generateResponseHandler = (reset) => (response) => {
    setOrdersCount(response.count);
    let newOrders;
    if (reset) {
      newOrders = response.results;
    } else {
      newOrders = [...orders, ...response.results];
    }
    setOrders(newOrders);
    setNextPage(response.next);
  };

  const handleBottomReached = () => {
    if (nextPage) {
      api.genericGet(nextPage).then(generateResponseHandler(false));
    }
  };

  const handleRefundSuccess = (order) => {
    for (const [idx, o] of orders.entries()) {
      if (o.pk === order.pk) {
        orders[idx] = order;
        setModalOrder(order);
        break;
      }
    }
  };

  const changePageType = (newPageType) => {
    if (newPageType === pageType) return;

    if (newPageType === "myOrders") {
      api.orders.listCreated().then(generateResponseHandler(true));
    } else if (newPageType === "allOrders") {
      api.orders.outletAll().then(generateResponseHandler(true));
    }

    setPageType(newPageType);
  };

  useEffect(() => {
    if (!orders) {
      if (pageType === "myOrders") {
        api.orders.listCreated().then(generateResponseHandler(true));
      } else if (pageType === "allOrders") {
        api.orders.outletAll().then(generateResponseHandler(true));
      }
    }
  });

  const handleShowOrderToCustomer = (order) => {
    if (socket && socket.readyState == 1) {
      socket.send(
        JSON.stringify({
          type: "order",
          message: order,
        })
      );
    }
  };

  const handleHide = () => {
    setShowOrderModal(false);
    if (socket && socket?.readyState == 1) {
      socket.send(
        JSON.stringify({
          type: "order",
          message: "",
        })
      );
    }
  };

  return (
    <Row className="OrdersPage h-100">
      <Col className="h-100" xs={12}>
        <Card className="h-100">
          <Card.Body className="h-100">
            {currentUser?.isOutletManager && (
              <Row className="mb-4">
                <Col className="mx-auto" xs={10}>
                  <Nav justify variant="pills" defaultActiveKey="myOrders">
                    <Nav.Item>
                      <Nav.Link
                        className="form-control"
                        eventKey="myOrders"
                        as={Button}
                        onClick={() => changePageType("myOrders")}
                        ref={navRef1}
                        onFocus={() => {
                          if (navRef1 && navRef1.current) {
                            navRef1.current.blur();
                          }
                        }}
                      >
                        My Orders
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        className="form-control"
                        eventKey="allOrders"
                        as={Button}
                        onClick={() => changePageType("allOrders")}
                        ref={navRef2}
                        onFocus={() => {
                          if (navRef2 && navRef2.current) {
                            navRef2.current.blur();
                          }
                        }}
                      >
                        All Orders
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={12}>
                <h1 className="text-center">
                  {pageType === "myOrders" && "My Previous Orders"}

                  {pageType === "allOrders" && "All Previous Orders"}
                </h1>
              </Col>
            </Row>

            <Row className="table-container">
              <Col className="h-100 mx-auto" xs={12}>
                <DataTable
                  config={{
                    keyField: "pk",
                    mapper: [
                      {
                        title: "Customer Name",
                        render: (order) => {
                          const customer = order.customer;
                          return `${customer.firstName} ${customer.lastName}`;
                        },
                      },
                      {
                        title: "Total",
                        render: (order) => `${order.total / 100} EGP`,
                      },
                      {
                        title: "Time",
                        render: (order) => getTimeString(order.createdAt),
                      },
                      {
                        title: "Order ID",
                        render: (order) => (
                          <span>
                            {order.pk}{" "}
                            {order.refundedAt && (
                              <span className="text-danger">(Refunded)</span>
                            )}
                          </span>
                        ),
                        clickable: () => true,
                        onClick: (order) => {
                          setGlobalScanDisabled(true);
                          setModalOrder(order);
                          setShowOrderModal(true);
                        },
                      },
                    ],
                  }}
                  data={orders}
                  dataCount={ordersCount}
                  showCount
                  onBottomReached={handleBottomReached}
                  dataType={"orders"}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <OrderModal
        show={showOrderModal}
        onHide={handleHide}
        onExited={() => {
          setModalOrder(null);
          setGlobalScanDisabled(false);
        }}
        order={modalOrder}
        customer={modalOrder?.customer}
        currentUser={currentUser}
        onRefundSuccess={handleRefundSuccess}
        onShowCustomer={handleShowOrderToCustomer}
        showCustomerBtn={true}
      />
    </Row>
  );
}
