import React, { useState, useRef, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import * as api from "../../../../api";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import RfidModal from "../../../../components/RfidModal/RfidModal";
import generateRandomToken from "../../../../utils/generateRandomToken";
import { getCartItemIndex } from "../../../../utils/getCartItemIndex";
import { promoTypes } from "../../../../utils/promoTypes";

import "./Cart.sass";

export default function Cart({
  data = [],
  onClearClick = () => {},
  onBuySuccess = () => {},
  onItemAdd = () => {},
  setGlobalScanDisabled = () => {},
  balance = "",
  socket = null,
  isPromoDisabled = () => {},
  removePromoItems = () => {},
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRfidModal, setShowRfidModal] = useState(false);
  const [successCustomer, setSuccessCustomer] = useState(null);
  const [token, setToken] = useState(generateRandomToken());
  const [errorMessage, setErrorMessage] = useState(null);
  const [formattedData, setFormattedData] = useState([]);

  const buyBtn = useRef(null);
  const cancelBtn = useRef(null);

  useEffect(() => {
    if (data !== null) {
      let dataCopy = [...data];
      let promoItems = dataCopy.filter(
        (item) => promoTypes[item?.item?.promo?.type] === "buy_x_get_y"
      );
      dataCopy = dataCopy.filter((item) => {
        if (!(promoTypes[item?.item?.promo?.type] === "buy_x_get_y")) {
          return item;
        }
      });
      let specials = {};
      promoItems.forEach(({ item, quantity }) => {
        if (specials[item.promo.id]) {
          specials[item.promo.id].items.push({ item, quantity });
        } else {
          specials[item.promo.id] = {
            items: [{ item, quantity }],
            title: item.promo.name,
          };
        }
      });
      let specialValues = Object.values(specials);
      let allData = [...dataCopy];
      for (let i = 0; i < specialValues.length; i++) {
        let originalIndex = getCartItemIndex(
          data,
          specialValues[i].items[0].item
        );
        specialValues[i].items[0] = {
          ...specialValues[i].items[0],
          promoTitle: specialValues[i].items[0].item.promo.name,
        };
        allData.splice(
          originalIndex != -1 ? originalIndex : allData.length - 1,
          0,
          ...specialValues[i].items
        );
      }
      setFormattedData(allData);
    }
  }, [data]);

  const handleBuy = async (rfid) => {
    setErrorMessage(null);
    const orderItems = data.map(({ item: i, quantity }) => ({
      item: i.pk,
      quantity,
      promotion: i?.promo?.id || null,
      is_special: i?.isSpecial ? true : false,
      user_promotion: i?.promo?.userPromotion || null,
    }));
    const order = await api.orders
      .create(orderItems, rfid, token)
      .catch((error) => {
        if (error.response.data.rfid) {
          setErrorMessage(error.response.data.rfid[0]);
        } else if (error.response.data.nonFieldErrors) {
          setErrorMessage(error.response.data.nonFieldErrors[0]);
          if (socket !== null && socket.readyState == 1) {
            socket.send(
              JSON.stringify({
                type: "popup",
                message: {
                  text: {
                    title: "Order Failed",
                    subtitle: error.response.data.nonFieldErrors[0],
                  },
                  type: "danger",
                },
              })
            );
          }
        } else {
          setErrorMessage("Something Went Wrong");
        }
      });

    if (order) {
      setSuccessCustomer(order.customer);
      onBuySuccess();
      setErrorMessage(null);
      if (socket !== null && socket.readyState == 1) {
        socket.send(
          JSON.stringify({
            type: "popup",
            message: {
              text: {
                title: "Order Successful",
                subtitle: `Remaining balance ${
                  order?.customer?.balance / 100
                } EGP`,
              },
              type: "success",
            },
          })
        );
      }
    }

    setToken(generateRandomToken());
  };

  const handleBuyClick = () => {
    if (!data || data.length === 0) return;

    if (socket && socket?.readyState == 1) {
      socket.send(
        JSON.stringify({
          type: "popup-special",
          message: { text: "Scan your RFID" },
        })
      );
    }
    setGlobalScanDisabled(true);
    setShowRfidModal(true);
  };

  const handleClear = () => {
    if (!data || data.length === 0) return;

    setShowConfirmModal(true);
    setGlobalScanDisabled(true);
  };

  const handleExit = () => {
    if (socket && socket?.readyState == 1) {
      socket.send(
        JSON.stringify({
          type: "popup",
          message: "close",
        })
      );
      if (successCustomer) {
        socket.send(
          JSON.stringify({
            type: "info",
            message: "success",
          })
        );
      }
    }
    setSuccessCustomer(null);
    setErrorMessage(null);
  };

  return (
    <Row className="Cart h-100 overflow-auto">
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <h1>Cart</h1>
          </Col>
        </Row>
        {balance && (
          <Row>
            <Col xs={12}>
              <h2 className="text-right">Balance: {balance}</h2>
            </Col>
          </Row>
        )}
        <Row>
          <div className="col-12">
            <Table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th></th>
                  <th className="text-center">Qty</th>
                  <th></th>
                  <th className="text-center">Price</th>
                  <th colSpan={2}></th>
                </tr>
              </thead>

              <tbody>
                {formattedData?.map(
                  ({ item, quantity, promoTitle = null }, index) => (
                    <React.Fragment>
                      {promoTitle && (
                        <tr>
                          <td colSpan={6}>
                            <div className="promoTitleContainer">
                              <h4 className="promoTitle">{promoTitle}</h4>
                              <Button
                                className="btn-cart"
                                variant="danger"
                                onClick={() => removePromoItems(item.promo.id)}
                              >
                                x
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr key={index}>
                        <td style={{ verticalAlign: "middle" }}>
                          <div
                            style={{
                              paddingLeft:
                                promoTypes[item?.promo?.type] === "buy_x_get_y"
                                  ? "18px"
                                  : "0",
                            }}
                          >
                            {item.name}
                          </div>
                          {promoTypes[item?.promo?.type] === "promo_code" ||
                            (promoTypes[item?.promo?.type] === "universal" && (
                              <div className="tdSecondary">
                                Discount {item.promo.discountPercent}%
                              </div>
                            ))}
                          {promoTypes[item?.promo?.type] === "buy_x_get_y" &&
                            item.isSpecial && (
                              <div
                                className="tdSecondary"
                                style={{
                                  paddingLeft:
                                    promoTypes[item?.promo?.type] ===
                                    "buy_x_get_y"
                                      ? "18px"
                                      : "0",
                                }}
                              >
                                Free
                              </div>
                            )}
                        </td>

                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {!(
                            promoTypes[item?.promo?.type] === "buy_x_get_y"
                          ) && (
                            <Button
                              className="btn-cart"
                              onClick={() => onItemAdd(item, -1)}
                              onFocus={(e) => e.target.blur()}
                            >
                              -
                            </Button>
                          )}
                        </td>

                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {quantity}
                        </td>

                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {!(
                            promoTypes[item?.promo?.type] === "buy_x_get_y"
                          ) && (
                            <Button
                              className="btn-cart"
                              onClick={() => onItemAdd(item)}
                              onFocus={(e) => e.target.blur()}
                              disabled={
                                item?.promo?.id
                                  ? isPromoDisabled(item?.promo?.id)
                                  : false
                              }
                            >
                              +
                            </Button>
                          )}
                        </td>

                        <td
                          className="text-center"
                          style={{
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              textDecoration:
                                promoTypes[item?.promo?.type] ===
                                  "promo_code" ||
                                promoTypes[item?.promo?.type] === "universal"
                                  ? "line-through"
                                  : promoTypes[item?.promo?.type] ===
                                      "buy_x_get_y" && item.isSpecial
                                  ? "line-through"
                                  : "none",
                            }}
                          >
                            {item?.promo?.type
                              ? (item.oldPrice / 100) * quantity
                              : (item.price / 100) * quantity}
                          </div>
                          {item.isSpecial && (
                            <div className="tdSecondary">
                              {(item.price / 100) * quantity}
                            </div>
                          )}
                        </td>

                        <td
                          className="text-center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {!(
                            promoTypes[item?.promo?.type] === "buy_x_get_y"
                          ) && (
                            <Button
                              className="btn-cart"
                              variant="danger"
                              onClick={() => onItemAdd(item, -quantity)}
                            >
                              x
                            </Button>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                )}

                <tr>
                  <th style={{ fontSize: 24 }} colSpan={3}>
                    Total
                  </th>

                  <th
                    className="text-right"
                    style={{ fontSize: 24 }}
                    colSpan={2}
                  >
                    {(data?.reduce(
                      (acc, { item, quantity }) => item.price * quantity + acc,
                      0
                    ) ?? 0) / 100}{" "}
                    EGP
                  </th>
                </tr>
              </tbody>
            </Table>
          </div>
        </Row>

        <Button
          variant="info"
          className="form-control"
          style={{ height: 100, fontSize: 30 }}
          onClick={handleBuyClick}
          ref={buyBtn}
          onFocus={() => {
            if (buyBtn && buyBtn.current) {
              buyBtn.current.blur();
            }
          }}
        >
          BUY
        </Button>

        <Button
          variant="danger"
          className="form-control mt-4"
          style={{ height: 100, fontSize: 30 }}
          onClick={handleClear}
          ref={cancelBtn}
          onFocus={() => {
            if (cancelBtn && cancelBtn.current) {
              cancelBtn.current.blur();
            }
          }}
        >
          CLEAR CART
        </Button>
      </Col>

      <ConfirmationModal
        show={showConfirmModal}
        onHide={() => {
          setShowConfirmModal(false);
          setGlobalScanDisabled(false);
        }}
        onConfirm={() => {
          onClearClick();
          setShowConfirmModal(false);
          setGlobalScanDisabled(false);
        }}
        onDecline={() => {
          setShowConfirmModal(false);
          setGlobalScanDisabled(false);
        }}
        prompt={"Are you sure you want to clear the cart?"}
      />

      <RfidModal
        show={showRfidModal}
        onHide={() => setShowRfidModal(false)}
        onExited={() => {
          handleExit();
          setGlobalScanDisabled(false);
        }}
        successMessage={"Order successful!"}
        successCustomer={successCustomer}
        onSubmit={handleBuy}
        errorMessage={errorMessage}
        title="Scan Customer RFID"
        ignoreFfsScan={false}
        socket={socket}
      />
    </Row>
  );
}
