import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as api from '../../../api';

import './LoginByRfidForm.sass'


export default function LoginByRfidForm({ onSuccess = () => {} }) {
  const { handleSubmit, register, formState: { errors } } = useForm();
  const [backendErrors, setBackendErrors] = useState({});

  const onSubmit = ({ rfid }) => {
    api.auth.loginByRfid(rfid).then((response) => {
      onSuccess(response);
    }).catch((error) => {
      if(error.response.status === 400) {
        setBackendErrors(error.response.data);
      } else if(error.response.status === 403) {
        setBackendErrors({ nonFieldErrors: ['You are not authorized to make that request'] });
      }
    });
  }

  return (
    <Row className="LoginByRfidForm">
      <Col xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              {
                backendErrors?.nonFieldErrors?.map((error) => (
                  <span key={`nonFieldErrors-${error}`} className="d-block error">
                    {error}
                  </span>
                ))
              }
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <label htmlFor="rfid">
                RFID
              </label>

              {errors.rfid && <span className="d-block error float-right">RFID is required</span>}
              <input
                {...register('rfid', { required: true })}
                className="form-control"
                type="password"
                name="rfid"
                id="rfid"
              />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  )
}
