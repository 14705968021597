import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function list(rfid) {
    const { data } = await axios.get(routes.customerPromotions(rfid), {
        ...AXIOS_DEFAULT_CONFIG
    });
    return data;
}