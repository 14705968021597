import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function entryAccess(qrCode, party) {
    const { data } = await axios.get(routes.entryAccess, {
        ...AXIOS_DEFAULT_CONFIG,
        params: {
            qr_code: qrCode,
            party: party
        },
    });

    return data;
}