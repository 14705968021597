import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function list() {
    const { data } = await axios.get(routes.partiesIndex, {
        ...AXIOS_DEFAULT_CONFIG
    });
    return data;
}