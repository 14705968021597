import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

export default async function ongroundCreate(
  email,
  firstName,
  lastName,
  gender,
  mobileNumber,
  rfid,
  tiers,
  action,
  paymentMethod,
  party
) {
  let body = {
    email,
    firstName,
    lastName,
    gender,
    mobileNumber,
    tiers,
    action,
    party
  }
  if (rfid) {
    body.rfid = rfid;
  }
  if (paymentMethod) {
    body.purchaseType = paymentMethod;
  }
  const { data } = await axios.post(
    routes.customersOngroundCreate,
    {
      ...body
    },
    AXIOS_DEFAULT_CONFIG
  );

  return data;
}
