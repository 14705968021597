const host = "onground.nacellegrooves.com";
const socketHost = "system.nacellegrooves.com";
const BASE_URL = `https://${host}/api/v1`;
/* const BASE_URL = `http://${host}/api/v1`; */

const CUSTOMERS_BASE_URL = `${BASE_URL}/customers`;
const AUTH_BASE_URL = `${BASE_URL}/auth`;
const CASHLESS_BASE_URL = `${BASE_URL}/cashless`;
const ACCESS_CONTROLLER_BASE_URL = `${BASE_URL}/access-controller`;
const CASH_TRANSACTIONS_BASE_URL = `${CASHLESS_BASE_URL}/cash-transactions`;
const EVENTS_BASE_URL = `${BASE_URL}/events`;

export const routes = {
  login: `${AUTH_BASE_URL}/login/`,
  loginByRfid: `${AUTH_BASE_URL}/rfid-login/`,
  logout: `${AUTH_BASE_URL}/logout/`,
  me: `${AUTH_BASE_URL}/me`,

  customersIndex: `${CUSTOMERS_BASE_URL}/`,
  customersShow: (pk) => `${CUSTOMERS_BASE_URL}/${pk}/`,
  customerRfid: (pk) => `${CUSTOMERS_BASE_URL}/${pk}/rfid/`,
  customersTellerCreate: `${CUSTOMERS_BASE_URL}/teller-create/`,
  customersAccessControlCreate: `${CUSTOMERS_BASE_URL}/access-control-create/`,
  customerByRfid: `${CUSTOMERS_BASE_URL}/rfid/`,
  customersOngroundCreate: `${CUSTOMERS_BASE_URL}/onground-create/`,
  customersChangeProfilePicture: `${CUSTOMERS_BASE_URL}/onground-change-profile-picture/`,
  customerAutoGenerate: `${CUSTOMERS_BASE_URL}/auto-generate/`,

  topUp: `${CASH_TRANSACTIONS_BASE_URL}/top-up/`,
  cashOut: `${CASH_TRANSACTIONS_BASE_URL}/cash-out/`,
  customerCashTransactions: (customerPk) =>
    `${BASE_URL}/customers/${customerPk}/cash-transactions/`,

  itemsIndex: `${CASHLESS_BASE_URL}/items/`,

  outletsIndex: `${CASHLESS_BASE_URL}/outlets/`,
  assignedOutlet: `${CASHLESS_BASE_URL}/outlets/assigned/`,

  ordersIndex: `${CASHLESS_BASE_URL}/orders/`,
  ordersCreatedIndex: `${CASHLESS_BASE_URL}/orders/created/`,
  orderRefund: (pk) => `${CASHLESS_BASE_URL}/orders/${pk}/refund/`,
  ordersOutletAll: `${CASHLESS_BASE_URL}/orders/outlet-all/`,

  ticketByQrCode: `${BASE_URL}/tickets/qr-code/`,

  entryAccess: `${ACCESS_CONTROLLER_BASE_URL}/entry-access/`,
  entryAccessByPK: `${ACCESS_CONTROLLER_BASE_URL}/entry-access-by-pk/`,
  accessLogs: `${CASHLESS_BASE_URL}/access_logs/`,

  partiesIndex: `${CASHLESS_BASE_URL}/parties/`,

  ticketTiersByEvent: (event_pk) =>
    `${EVENTS_BASE_URL}/${event_pk}/ticket-tiers/`,
  vendorSocket: (id) => `wss://${socketHost}/ws/vendor/${id}/`,

  customerPromotions: (rfid) =>
    `${BASE_URL}/promotions/vendor_promotion/?rfid=${rfid}`,

  activatePass: (ticketPass) =>
    `${ACCESS_CONTROLLER_BASE_URL}/${ticketPass}/activate-pass/`,

  endShift: `${BASE_URL}/cashless/end-shift/`,

  checkIn: `${BASE_URL}/tickets/check-in/`
};

export const AXIOS_DEFAULT_CONFIG = {
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  contentType: "application/json",
};

export const DEFAULT_DEBOUNCE_TIME = 1000;
