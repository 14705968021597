import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

export default async function checkIn(party, customer) {
    const response = await axios.post(
        routes.checkIn,
        {
            party, customer
        },
        {
            ...AXIOS_DEFAULT_CONFIG,
        }
    );

    return response;
}
