import React, { useRef } from "react";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import noImage from "../../assets/images/no-image.jpg";
import "./ItemButton.sass";

export default function ItemButton({
  item,
  onClick = () => {},
  withPicture = false,
  isDisabled = false,
}) {
  const btn = useRef(null);
  return (
    <Button
      variant="outline-primary"
      className="ItemButton"
      onClick={onClick}
      disabled={isDisabled}
      ref={btn}
      onFocus={() => {
        if (btn && btn.current) {
          btn.current.blur();
        }
      }}
    >
      <Row>
        {withPicture && (
          <Col xs={6} className="img-container">
            <img className="img" src={item.picture || noImage} />
          </Col>
        )}
        <Col xs={6}>
          <div className="text-container">
            <div className="name-container">
              <span className="name">{item.name}</span>
            </div>
            <div className="price-container">
              {item?.price && (
                <span className="price">{item.price / 100} EGP</span>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Button>
  );
}
