import axios from "axios";

import { AXIOS_DEFAULT_CONFIG, routes } from "../../constants";

export default async function accessControlCreate(
  email,
  firstName,
  lastName,
  gender,
  mobileNumber,
  rfid
) {
  const { data } = await axios.post(
    routes.customersAccessControlCreate,
    {
      email,
      firstName,
      lastName,
      gender,
      mobileNumber,
      rfid,
    },
    AXIOS_DEFAULT_CONFIG
  );

  return data;
}
