import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function cashOut(body) {
  const { data } = await axios.post(routes.cashOut, {
    ...body
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
