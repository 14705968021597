import React, { useState, useEffect, useRef } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as api from "../../../../api";
import generateRandomToken from "../../../../utils/generateRandomToken";
import RfidModal from "../../../../components/RfidModal/RfidModal";

import "./TopUpOrCashOut.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CashOrCreditModal from "../../../../components/CashOrCreditModal/CashOrCreditModal";
import GlobalScan from "../../../../components/GlobalScan/globalScan";
import CustomToast from "../../../../components/Toast/toast";
import CustomerModal from "./../../../../components/CustomerModal/CustomerModal";

const buttonAmounts = [5, 10, 20, 50, 100, 200, 500, 1000];

export default function TopUpOrCashOut({ type = "topUp", currentUser }) {
  const [amount, setAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState(generateRandomToken());
  const [successCustomer, setSuccessCustomer] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [compCash, setCompCash] = useState(false);
  const [method, setMethod] = useState("");
  const [showMethodModal, setShowMethodModal] = useState(false);
  const [globalScanDisabled, setGlobalScanDisabled] = useState(false);
  const [customer, setCustomer] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const inputRef = useRef(null);
  const submitRef = useRef(null);
  const resetRef = useRef(null);

  useEffect(() => {
    if (showMethodModal || showModal) {
      setGlobalScanDisabled(true);
    } else {
      setGlobalScanDisabled(false);
    }
  }, [showMethodModal, showModal]);

  useEffect(() => {
    if (Object.keys(customer)?.length > 0) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [customer]);

  useEffect(() => {
    if (!showCustomerModal && !showToast) {
      setCustomer({});
      setTransactions([]);
    }
  }, [showToast, showCustomerModal]);

  const handleSubmit = async (rfid) => {
    setErrorMessage(null);
    let body = { amount: amount * 100, rfid, token };
    if (type === "topUp" && compCash) {
      body.isNcCash = true;
      body.transactionMethod = "system";
    } else if (type === "topUp" && !compCash) {
      body.transactionMethod = method;
    }
    const customer = await api.cashTransactions[type](body).catch((error) => {
      const data = error.response.data;
      const dataKeys = Object.keys(data);
      setErrorMessage(data[dataKeys[0]][0]);
    });
    setSuccessCustomer(customer);

    if (customer) {
      setAmount(0);
      setCompCash(false);
      setMethod("");
      setErrorMessage(null);
    }

    setToken(generateRandomToken());
  };

  const handleHide = () => {
    setShowModal(false);
  };
  const handleExited = () => {
    setSuccessCustomer(false);
    setErrorMessage(null);
  };

  const onButtonClick = (addAmount) => {
    setAmount(amount + addAmount);
  };
  const handleChange = (value) => {
    value = parseInt(value);

    if (isNaN(value)) setAmount(0);
    else setAmount(value);
  };

  const handleInputSubmit = () => {
    if (type === "topUp" && !compCash) {
      setShowMethodModal(true);
    } else {
      setShowModal(true);
    }
  };

  const handleMethodChoice = (choice) => {
    setMethod(choice);
    setShowModal(true);
    setShowMethodModal(false);
  };

  const fetchCustomerTransactions = () => {
    api.cashTransactions
      .byCustomer(customer.pk)
      .then((response) => setTransactions(response.results));
  };

  return (
    <Row className="TopUpOrCashOut">
      <Col xs={12} lg={8} md={7}>
        <Row>
          {buttonAmounts.map((amount) => (
            <Col key={amount} className="text-center my-3" xs={6} md={4} lg={3}>
              <Button
                variant={type === "topUp" ? "success" : "danger"}
                className="btn-lg btn-square"
                onClick={() => onButtonClick(amount)}
              >
                {amount}
              </Button>
            </Col>
          ))}
        </Row>
      </Col>

      <Col className="text-center" lg={4} md={5} xs={12}>
        <div className="mb-4 cash-value-area">
          <div className="cash-value-input pr-4">
            <input
              className="form-control p-0 border-0"
              style={{ fontSize: 40 }}
              type="number"
              name="amount"
              id="amount"
              value={amount}
              onChange={(event) => handleChange(event.target.value)}
              ref={inputRef}
              onFocus={() => setGlobalScanDisabled(true)}
              onBlur={() => setGlobalScanDisabled(false)}
            />
          </div>

          <Button
            variant="info"
            className="cash-value-reset"
            onClick={() => setAmount(0)}
            ref={resetRef}
            onFocus={() => resetRef?.current?.blur()}
          >
            Reset
          </Button>
        </div>
        <Button
          disabled={!amount}
          variant="primary"
          className="form-control w-100"
          style={{ fontSize: 32, height: 150, width: 200 }}
          onClick={() => handleInputSubmit()}
          ref={submitRef}
          onFocus={() => submitRef?.current?.blur()}
        >
          Proceed
        </Button>
        {currentUser?.canAddNcCash && type === "topUp" && (
          <div className="comp-cash-div mt-3 w-100">
            <div
              className={
                "comp-cash-btn" + (compCash ? " comp-cash-btn--active" : "")
              }
              onClick={() => setCompCash(!compCash)}
            >
              {compCash && (
                <FontAwesomeIcon icon={faCheck} className="check-icon" />
              )}
            </div>
            <div
              className="comp-cash-label"
              onClick={() => setCompCash(!compCash)}
            >
              Comp Cash
            </div>
          </div>
        )}
      </Col>

      <RfidModal
        show={showModal}
        onHide={handleHide}
        onExited={handleExited}
        onSubmit={handleSubmit}
        successMessage={
          (type === "topUp" ? "Top up" : "Cash out") + " successful!"
        }
        successCustomer={successCustomer}
        errorMessage={errorMessage}
        title="Scan Customer RFID"
        showNfcButton={true}
      />
      <CashOrCreditModal
        show={showMethodModal}
        onHide={() => setShowMethodModal(false)}
        handleChoice={handleMethodChoice}
      />
      <GlobalScan
        onScan={(value) => setCustomer(value)}
        globalScanDisabled={globalScanDisabled}
        setGlobalScanDisabled={setGlobalScanDisabled}
      />
      <CustomToast
        show={showToast}
        onClose={() => setShowToast(false)}
        body={`Balance: ${customer?.balance / 100} EGP`}
        header={customer?.firstName + " " + customer?.lastName}
        handleToastClick={() => setShowCustomerModal(true)}
        timeout={10}
      />
      <CustomerModal
        show={showCustomerModal}
        onHide={() => {
          setShowToast(false);
          setShowCustomerModal(false);
          setCustomer({});
          setTransactions([]);
        }}
        onExited={() => {
          setShowToast(false);
          setShowCustomerModal(false);
          setCustomer({});
          setTransactions([]);
        }}
        onShow={fetchCustomerTransactions}
        customer={customer}
        cashTransactions={transactions}
      />
    </Row>
  );
}
