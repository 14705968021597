import React from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import CashPage from "./CashPage/CashPage";
import ItemsPage from "./ItemsPage/ItemsPage";
import UsersPage from "./UsersPage/UsersPage";

import "./Tellers.sass";

export default function Tellers({ currentUser, party, event, rfidSettings }) {
  const { url, isExact } = useRouteMatch();
  const { pathname } = useLocation();
  const usersPath = `${url}/users`;
  const cashPath = `${url}/cash`;
  const itemsPath = `${url}/items`;

  return (
    <Row className="Tellers">
      {isExact && <Redirect to={usersPath} />}

      <Col className="mt-4" xs={12}>
        <Nav justify variant="pills" defaultActiveKey="users">
          <Nav.Item>
            <Nav.Link
              eventKey="users"
              active={usersPath === pathname}
              as={Link}
              to={usersPath}
            >
              Customers
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="cash"
              active={pathname.startsWith(cashPath)}
              as={Link}
              to={cashPath}
            >
              Cash
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              eventKey="items"
              active={pathname === itemsPath}
              as={Link}
              to={itemsPath}
            >
              Items
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <h2 className="mt-2 ml-3">{party?.name}</h2>
      <Col style={{ height: "calc(100% - 64px)" }} xs={12}>
        <Switch>
          <Route exact path={usersPath}>
            <UsersPage
              party={party}
              event={event}
              rfidSettings={rfidSettings}
            />
          </Route>

          <Route path={cashPath}>
            <CashPage currentUser={currentUser} />
          </Route>

          <Route path={itemsPath}>
            <ItemsPage />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
}
