import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ItemButton from "./../ItemButton/ItemButton";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./PromoItemsModal.sass";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PromoItemsModal = ({
  show = false,
  onHide = () => {},
  items = [],
  title = "",
  promo = null,
  onItemAdd = () => {},
  isPromoDisabled = () => {},
  cartData = [],
}) => {
  const [totalPromoItems, setTotalPromoItems] = useState(0);
  const [selectedPromoItems, setSelectedPromoItems] = useState(0);

  useEffect(() => {
    setTotalPromoItems(promo?.maxUses || 0);
    setSelectedPromoItems(promo?.used || 0);
  }, [cartData, promo]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      className="promoItemsModal"
      backdropClassName="customBackdrop"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>
          <h2>{title}</h2>
        </Modal.Title>
        <FontAwesomeIcon icon={faTimes} size={"lg"} onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <h3>
              Selected: {selectedPromoItems}/{totalPromoItems}
            </h3>
          </Col>
        </Row>
        <Row>
          {items.map((item) => (
            <Col className="mb-5" xs={6} md={4} lg={3} xl={2} key={item.pk}>
              <ItemButton
                item={{
                  ...item,
                  oldPrice: item.price,
                  price: item.discountedPrice,
                }}
                onClick={() =>
                  onItemAdd(
                    {
                      ...item,
                      name: item.name,
                      promo: {
                        id: promo.promo,
                        type: promo.type,
                        discountPercent: promo.discountPercent,
                        userPromotion: promo.userPromotion,
                        name: promo.title,
                      },
                      maxUses: promo.maxUses,
                      oldPrice: item.price,
                      price: item.discountedPrice,
                      isSpecial: false,
                    },
                    1
                  )
                }
                isDisabled={isPromoDisabled(promo.promo)}
                withPicture={true}
              />
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PromoItemsModal;
