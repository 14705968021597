import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as api from "../../../api";
import { DEFAULT_DEBOUNCE_TIME } from "../../../constants";
import DataTable from "../../../components/DataTable/DataTable";
import SearchField from "../../../components/fields/SearchField/SearchField";

import "./ItemsPage.sass";

export default function ItemsPage() {
  const [items, setItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);
  const [nextPage, setNextPage] = useState(null);

  const onQueryChange = useDebouncedCallback((query) => {
    if (query === "") query = null;

    api.items.list(query).then(generateResponseHandler(true));
  }, DEFAULT_DEBOUNCE_TIME);

  const generateResponseHandler = (reset) => (response) => {
    setItemsCount(response.count);
    let newItems;
    if (reset) {
      newItems = response.results;
    } else {
      newItems = [...items, ...response.results];
    }
    setItems(newItems);
    setNextPage(response.next);
  };

  let requestAlreadySent = false;
  const onBottomReached = () => {
    if (requestAlreadySent || !nextPage) return;

    requestAlreadySent = true;
    api.genericGet(nextPage).then(generateResponseHandler(false));
  };

  useEffect(() => {
    if (!items) {
      api.items.list().then(generateResponseHandler(true));
    }
  });

  return (
    <Row className="ItemsPage">
      <Col className="h-100" xs={12}>
        <Card className="h-100">
          <Card.Body>
            <Card.Title className="text-center">
              <h1>Items</h1>
            </Card.Title>

            <Row>
              <Col className="mx-auto" xs={12} md={10}>
                <SearchField onQueryChange={onQueryChange} />
              </Col>
            </Row>

            <Row className="mt-4 table-container">
              <Col className="mx-auto h-100" xs={12} md={10}>
                <DataTable
                  config={{
                    keyField: "pk",
                    mapper: [
                      {
                        title: "Name",
                        dataKey: "name",
                      },
                      {
                        title: "Price",
                        render: (item) => `${item.price / 100} EGP`,
                      },
                      {
                        title: "Outlets",
                        render: (item) =>
                          item.outlets.map((outlet) => outlet.name).join(", "),
                      },
                    ],
                  }}
                  data={items}
                  dataCount={itemsCount}
                  onBottomReached={onBottomReached}
                  showCount
                  dataType={"items"}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
