import React, { useState, useEffect, useRef } from "react";
import * as api from "./../../api";

const GlobalScan = ({ onScan = () => {}, globalScanDisabled = true }) => {
  const [rfid, setRfid] = useState("");

  const rfidTimeout = useRef(null);

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    handleRfidReset();
    handleRfidSubmit();
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [rfid, globalScanDisabled]);

  const handleKeyPress = (e) => {
    if (globalScanDisabled) {
      return;
    }
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      setRfid((current) => {
        return current + e.key;
      });
    }
  };

  const handleRfidSubmit = () => {
    if (rfid.length === 10) {
      let customerRfid = rfid;
      getCustomerAndBalance(customerRfid);
    }
  };

  const handleRfidReset = () => {
    if (rfid.length === 0) {
      if (rfidTimeout.current) {
        clearTimeout(rfidTimeout.current);
      }
    } else {
      if (rfidTimeout.current === null) {
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 250);
      } else {
        clearTimeout(rfidTimeout.current);
        rfidTimeout.current = setTimeout(() => {
          setRfid("");
        }, 250);
      }
    }
  };

  const getCustomerAndBalance = (customerRfid) => {
    if (globalScanDisabled) {
      return;
    }
    api.customers.byRfid(customerRfid).then((data) => {
      onScan(data);
    });
  };
  return <></>;
};

export default GlobalScan;
