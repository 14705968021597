import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as api from "../../../api";
import RfidModal from "../../RfidModal/RfidModal";

import Select from "react-select";

import "./UserForm.sass";
import TierButton from "../../tierButton/tierButton";
import CashOrCreditModal from "../../CashOrCreditModal/CashOrCreditModal";
import Autocomplete from "../../AutocompleteList/Autocomplete";
import { Button } from "react-bootstrap";

export default function UserForm({ onSuccess = () => {}, party = {}, event }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
    clearErrors,
  } = useForm();
  const [backendErrors, setBackendErrors] = useState({});
  const [successMessageCountdown, setSuccessMessageCountdown] = useState(0);
  const [showRfidModal, setShowRfidModal] = useState(false);
  const [successCustomer, setSuccessCustomer] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tiers, setTiers] = useState([]);
  const [selectedTiers, setSelectedTiers] = useState({});
  const [tiersConflicts, setTiersConflicts] = useState({});
  const [tiersError, setTiersError] = useState("");
  const [purchaseTypes] = useState([
    { label: "Paid", value: "onground" },
    { label: "Free of charge", value: "foc" },
  ]);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState(
    purchaseTypes[0]
  );
  const [creationType, setCreationType] = useState("");
  const [creationTypeError, setCreationTypeError] = useState("");
  const [showMethodModal, setShowMethodModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [expandAutocomplete, setExpandAutocomplete] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  const watchMobileField = watch("mobileNumber");

  const onSubmit = ({ email, firstName, lastName, gender, mobileNumber }) => {
    let error = false;
    if (Object.keys(selectedTiers).length === 0) {
      setTiersError("Please select at elast 1 ticket tier");
      error = true;
    } else {
      setTiersError("");
    }
    if (!creationType) {
      setCreationTypeError("Please select creation flow");
      error = true;
    } else {
      setCreationTypeError("");
    }
    if (!error) {
      if (selectedPurchaseType?.value != "foc") {
        setShowMethodModal(true);
      } else {
        setPaymentMethod(selectedPurchaseType?.value);
        if (creationType === "assign_rfid") {
          setShowRfidModal(true);
        } else {
          submit(null, selectedPurchaseType?.value == "foc" ? "foc" : "");
        }
      }
    } else {
      return;
    }
  };

  const submit = (rfid = null, choice = "") => {
    setErrorMessage(null);
    const { email, firstName, lastName, gender, mobileNumber } = getValues();
    let selectedArray = [];
    for (const tier in selectedTiers) {
      selectedArray = selectedArray.concat(tier);
    }
    api.customers
      .onGroundCreate(
        email,
        firstName,
        lastName,
        gender,
        mobileNumber,
        rfid,
        selectedArray,
        creationType,
        choice,
        party.pk
      )
      .then((response) => {
        onSuccess(response);
        setSuccessCustomer(response);
        setSelectedTiers({});
        setTiersConflicts({});
        reset();
        setSuccessMessageCountdown(5);
        setBackendErrors({});
        setErrorMessage(null);
        setPaymentMethod("");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400
        ) {
          setBackendErrors(error.response.data);
        } else if (error.response.status === 403) {
          setBackendErrors({ nonFieldErrors: [error.response.data.detail] });
        }
        const data = error.response.data;
        const dataKeys = Object.keys(data);
        setErrorMessage(data[dataKeys[0]][0]);
      });
  };

  useEffect(() => {
    if (successMessageCountdown > 0) {
      setTimeout(
        () => setSuccessMessageCountdown(successMessageCountdown - 1),
        1000
      );
    } else {
      setSuccessCustomer(null);
    }
  }, [successMessageCountdown]);

  useEffect(() => {
    if (!event) return;
    const loadTiers = async () => {
      let data = await api.events.ticketTiersByEvent(event);
      let available_tiers = data.results.filter((tier) => {
        if (!tier.isSoldOut) {
          return tier;
        }
      });
      setTiers(
        available_tiers.map((tier) => {
          return {
            label: `${tier.name} ${tier.nameLine2} ${tier.nameLine3}`,
            value: tier.pk,
            conflicts: tier.conflictingTiers,
          };
        })
      );
      if (available_tiers?.length == 1) {
        handleTierSelect(
          available_tiers[0]?.pk,
          available_tiers[0]?.conflictingTiers
        );
      }
    };
    loadTiers();
  }, [event]);

  useEffect(() => {
    if (watchMobileField?.length >= 11) {
      api.customers.list(watchMobileField, true).then((res) => {
        let list = res.results?.map((item) => {
          return {
            id: item.pk,
            label:
              item.email +
              " - " +
              item.firstName +
              " " +
              item.lastName +
              " - " +
              item?.mobileNumber,
            email: item.email,
            gender: item.gender,
            firstName: item.firstName,
            lastName: item.lastName,
            mobileNumber: item.mobileNumber,
          };
        });
        setCustomerList(list);
        if (list?.length > 0) {
          setExpandAutocomplete(true);
        }
      });
    } else {
      setCustomerList([]);
    }
  }, [watchMobileField]);

  const handleTierSelect = (value, conflicts) => {
    let selected = { ...selectedTiers };
    let currentConflicts = { ...tiersConflicts };
    if (selected[value]) {
      delete selected[value];
      currentConflicts = {};
      for (const tier in selected) {
        selected[tier].conflicts.map((conflict) => {
          if (!currentConflicts[conflict]) {
            currentConflicts[conflict] = true;
          }
        });
      }
    } else {
      selected[value] = {};
      selected[value].id = value;
      selected[value].conflicts = conflicts;
      conflicts.map((conflict) => {
        if (!currentConflicts[conflict]) {
          currentConflicts[conflict] = true;
        }
      });
    }
    setTiersConflicts(currentConflicts);
    setSelectedTiers(selected);
  };

  const handleCreationTypeChange = (value) => {
    setCreationType(value);
  };

  const handleMethodChoice = (choice) => {
    setPaymentMethod(choice);
    setShowMethodModal(false);
    if (creationType === "assign_rfid") {
      setShowRfidModal(true);
    } else {
      submit(null, choice);
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setExpandAutocomplete(false);
    if (getValues("mobileNumber")) {
      clearErrors("mobileNumber");
    }
    setValue("email", customer?.email, { shouldValidate: true });
    setValue("firstName", customer?.firstName, { shouldValidate: true });
    setValue("lastName", customer?.lastName, { shouldValidate: true });
    setValue("gender", customer?.gender, { shouldValidate: true });
  };

  const handleClearAutocomplete = (customer) => {
    setSelectedCustomer({});
    setValue("mobileNumber", "", { shouldValidate: true });
    setValue("email", "", { shouldValidate: true });
    setValue("firstName", "", { shouldValidate: true });
    setValue("lastName", "", { shouldValidate: true });
    setValue("gender", "", { shouldValidate: true });
  };

  const handleAutoGenerate = () => {
    api.customers
      .autoGenerate()
      .then(({ email, firstName, lastName, gender, mobileNumber }) => {
        setValue("mobileNumber", mobileNumber, { shouldValidate: true });
        setValue("email", email, { shouldValidate: true });
        setValue("firstName", firstName, { shouldValidate: true });
        setValue("lastName", lastName, { shouldValidate: true });
        setValue("gender", gender, { shouldValidate: true });
      });
  };

  return (
    <React.Fragment>
      <p className="error" style={{ fontSize: "20px" }}>
        {errorMessage}
      </p>
      <Row className="UserForm" onClick={() => setExpandAutocomplete(false)}>
        {tiersError && (
          <span className="d-block error float-right mb-3">{tiersError}</span>
        )}
        <div className="ticket-tier-select-area">
          {tiers.map((tier, index) => (
            <React.Fragment key={index}>
              <Col xs={4} className="tier-button-container">
                <TierButton
                  label={tier.label}
                  value={tier.value}
                  onSelect={handleTierSelect}
                  status={
                    selectedTiers[tier.value]
                      ? "selected"
                      : tiersConflicts[tier.value]
                      ? "disabled"
                      : ""
                  }
                  conflicts={tier.conflicts}
                />
              </Col>
            </React.Fragment>
          ))}
        </div>
        <Col xs={12} md={12} lg={12}>
          {creationTypeError && <p className="error">{creationTypeError}</p>}
          <div className="payment-and-creation">
            <Select
              isSearchable={false}
              options={purchaseTypes}
              value={selectedPurchaseType}
              onChange={(selected) => setSelectedPurchaseType(selected)}
              className="customer-payment-type"
            />
            <div className="creation-type">
              <label htmlFor="create">
                <input
                  type="radio"
                  value="create"
                  id="create"
                  name="creation-type"
                  checked={creationType === "create"}
                  onChange={(e) => handleCreationTypeChange(e.target.value)}
                />{" "}
                Create Only
              </label>
              <label htmlFor="check_in">
                <input
                  type="radio"
                  value="check_in"
                  id="check_in"
                  name="creation-type"
                  checked={creationType === "check_in"}
                  onChange={(e) => handleCreationTypeChange(e.target.value)}
                />{" "}
                Create And Check-in
              </label>
              <label htmlFor="assign_rfid">
                <input
                  type="radio"
                  value="assign_rfid"
                  id="assign_rfid"
                  name="creation-type"
                  checked={creationType === "assign_rfid"}
                  onChange={(e) => handleCreationTypeChange(e.target.value)}
                />{" "}
                Create And Assign RFID
              </label>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {successMessageCountdown > 0 && (
              <Row className="mb-4">
                <Col xs={12}>
                  <span className="d-block text-center text-success">
                    Customer created successfully
                  </span>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={12} md={6} className="mb-2">
                <label htmlFor="email">Email</label>

                {errors.email && (
                  <span className="d-block error float-right mt-1">
                    Email is required
                  </span>
                )}
                {backendErrors.email?.map((error) => (
                  <span className="d-block error float-right mt-1">
                    {error}
                  </span>
                ))}
                <input
                  {...register("email", { required: true })}
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                />
              </Col>

              <Col xs={12} md={6} className="mb-2">
                <label htmlFor="firstName">First Name</label>

                {errors.firstName && (
                  <span className="d-block error float-right mt-1">
                    First name is required
                  </span>
                )}
                {backendErrors.firstName?.map((error) => (
                  <span className="d-block error float-right mt-1">
                    {error}
                  </span>
                ))}
                <input
                  {...register("firstName", { required: true })}
                  className="form-control"
                  type="text"
                  name="firstName"
                  id="firstName"
                />
              </Col>

              <Col xs={12} md={6} className="mb-2">
                <label htmlFor="lastName">Last Name</label>

                {errors.lastName && (
                  <span className="d-block error float-right mt-1">
                    Last name is required
                  </span>
                )}
                {backendErrors.lastName?.map((error) => (
                  <span className="d-block error float-right mt-1">
                    {error}
                  </span>
                ))}
                <input
                  {...register("lastName", { required: true })}
                  className="form-control"
                  type="text"
                  name="lastName"
                  id="lastName"
                />
              </Col>

              <Col xs={12} md={6} className="mb-2">
                <label htmlFor="gender">Gender</label>

                {errors.gender && (
                  <span className="d-block error float-right mt-1">
                    Gender is required
                  </span>
                )}
                {backendErrors.gender?.map((error) => (
                  <span className="d-block error float-right mt-1">
                    {error}
                  </span>
                ))}
                <div>
                  <label htmlFor="male" className="mr-3">
                    <input
                      {...register("gender")}
                      type="radio"
                      value="m"
                      id="male"
                      name="gender"
                      className="mr-1"
                    />
                    Male
                  </label>
                  <label htmlFor="female" className="mr-3">
                    <input
                      {...register("gender")}
                      type="radio"
                      value="f"
                      id="female"
                      name="gender"
                      className="mr-1"
                    />
                    Female
                  </label>
                </div>
              </Col>

              <Col xs={12} md={6} className="mb-3">
                <label htmlFor="mobileNumber">Mobile Number</label>

                {errors.mobileNumber && (
                  <span className="d-block error float-right mt-1">
                    Mobile number is required
                  </span>
                )}
                {backendErrors.mobileNumber?.map((error) => (
                  <span className="d-block error float-right mt-1">
                    {error}
                  </span>
                ))}
                <div className="autocomplete-container">
                  <input
                    {...register("mobileNumber", { required: true })}
                    className="form-control"
                    type="tel"
                    name="mobileNumber"
                    id="mobileNumber"
                    autoComplete="off"
                    onClick={(e) => e.stopPropagation()}
                    onFocus={() => setExpandAutocomplete(true)}
                  />
                  <Autocomplete
                    onSelect={handleCustomerSelect}
                    list={customerList}
                    selected={selectedCustomer}
                    expanded={expandAutocomplete}
                    onExpand={setExpandAutocomplete}
                    onClear={handleClearAutocomplete}
                    showCount={true}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <div className="auto-generate-container">
                  <Button variant="info" onClick={handleAutoGenerate}>
                    {" "}
                    Auto Generate
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={12}>
                <input
                  className="form-control btn btn-primary"
                  type="submit"
                  value="Create"
                />
              </Col>
            </Row>
          </form>
        </Col>

        <RfidModal
          show={showRfidModal}
          onHide={() => setShowRfidModal(false)}
          onExited={() => {
            setSuccessCustomer(null);
            setErrorMessage(null);
          }}
          successMessage="Customer created successfully"
          successCustomer={successCustomer}
          errorMessage={errorMessage}
          title="Assign RFID"
          hideBalance={true}
          onSubmit={(rfid) => {
            submit(rfid, paymentMethod);
          }}
          showNfcButton={true}
        />
      </Row>
      <CashOrCreditModal
        show={showMethodModal}
        onHide={() => setShowMethodModal(false)}
        handleChoice={handleMethodChoice}
      />
    </React.Fragment>
  );
}
