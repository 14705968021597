import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import * as api from "./api";
import AccessControl from "./pages/AccessControl/AccessControl";
import LoginPage from "./pages/LoginPage/LoginPage";
import Tellers from "./pages/Tellers/Tellers";
import Vendors from "./pages/Vendors/Vendors";

import "./App.scss";
import LogoutModal from "./components/LogoutModal/LogoutModal";

function App() {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [party, setParty] = useState({});
  const [event, setEvent] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [reportError, setReportError] = useState("");
  const [rfidSettings, setRfidSettings] = useState({});

  const handleLogoutModal = (endShift = false) => {
    setReportError("");
    if (endShift) {
      api.reports
        .endShift()
        .then((response) => {
          if (
            response?.status &&
            response.status >= 200 &&
            response.status < 300
          ) {
            handleLogout();
          }
        })
        .catch((response) => {
          setReportError("Something went wrong while ending shift");
        });
    } else {
      handleLogout();
    }
  };

  const handleLogout = () => {
    api.auth.logout();
    setShowLogoutModal(false);
    setCurrentUser(null);
    setShowLogoutModal(false);
    history.replace("/");
  };

  useEffect(() => {
    if (!currentUser) {
      api.auth
        .me()
        .then((data) => {
          assignUserAndparty(data);
        })
        .catch(() => {
          history.replace("/");
        });
    }
  }, [currentUser]);

  const assignUserAndparty = (user) => {
    if (user.isTeller || user.isAccessControl) {
      let assignedPartyId = user.assignedParty;
      api.parties.list().then((response) => {
        let assignedParty = response.results.filter(
          (party) => party.pk === assignedPartyId
        );
        setParty(assignedParty[0]);
        setEvent(assignedParty[0]?.event);
        setRfidSettings({
          chargeForRfidAssignment: assignedParty[0]?.chargeForRfidAssignment,
          chargeForRfidReplacement: assignedParty[0]?.chargeForRfidReplacement,
          price: assignedParty[0]?.itemPrice / 100,
        });
      });
    }
    setCurrentUser(user);
  };

  return (
    <Container fluid className="App">
      <Row className="content">
        <Col xs={12}>
          <Switch>
            <Route exact path="/">
              <LoginPage
                onSuccess={assignUserAndparty}
                currentUser={currentUser}
              />
            </Route>

            {currentUser && (
              <React.Fragment>
                <Route path="/access-control">
                  <AccessControl
                    currentUser={currentUser}
                    party={party}
                    event={event}
                    rfidSettings={rfidSettings}
                  />
                </Route>

                <Route path="/tellers">
                  <Tellers
                    party={party}
                    event={event}
                    currentUser={currentUser}
                    rfidSettings={rfidSettings}
                  />
                </Route>

                <Route path="/vendors">
                  <Vendors currentUser={currentUser} />
                </Route>
              </React.Fragment>
            )}
            <Redirect to="/" />
          </Switch>
        </Col>
      </Row>

      <Row className="custom-nav">
        <Col className="mt-3" xs={12}>
          {currentUser && (
            <Button
              className="float-right"
              onClick={() => setShowLogoutModal(true)}
            >
              Logout
            </Button>
          )}
        </Col>
      </Row>
      <LogoutModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        handleLogout={handleLogoutModal}
        error={reportError}
      />
    </Container>
  );
}

function RouterWrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
export default RouterWrappedApp;
