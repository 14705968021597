import React, { useState, useRef, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./RFIDField.sass";
import { useDebouncedCallback } from "use-debounce";
import NfcButton from "../../NfcButton/NfcButton";

const RFIDField = ({
  onSubmit = () => {},
  active = true,
  showNfcButton = false,
}) => {
  const [RFID, setRFID] = useState("");

  const [nfcReadingEnabled, setNfcReadingEnabled] = useState(false);

  const rfidRef = useRef(null);
  const rfidTimeout = useRef(null);

  const handleChange = (value) => {
    if (document.activeElement == rfidRef.current) {
      setRFID(value);
      if (value.length === 10) {
        onSubmit(value);
        setRFID("");
      } else if (value.length > 10) {
        setRFID("");
      } else {
        handleRfidReset();
      }
    }
  };

  const handleRfidReset = () => {
    if (RFID.length === 0) {
      if (rfidTimeout.current) {
        clearTimeout(rfidTimeout.current);
      }
    } else {
      if (rfidTimeout.current === null) {
        rfidTimeout.current = setTimeout(() => {
          setRFID("");
        }, 1000);
      } else {
        clearTimeout(rfidTimeout.current);
        rfidTimeout.current = setTimeout(() => {
          setRFID("");
        }, 1000);
      }
    }
  };

  const focusRFID = () => {
    if (rfidRef.current !== null) {
      rfidRef.current.focus();
    }
  };

  useEffect(() => {
    focusRFID();
  }, [active, nfcReadingEnabled]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <label htmlFor="RFIDField">RFID</label>
          <Button className="float-right" onClick={() => setRFID("")}>
            Clear
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="rfid-input-and-status mt-2">
            <div
              className={
                active
                  ? "rfid-status rfid-status--active"
                  : "rfid-status rfid-status--disabled"
              }
            ></div>
            <input
              ref={rfidRef}
              className="form-control"
              type="text"
              name="RFIDField"
              id="RFIDField"
              value={RFID}
              onChange={(event) => handleChange(event.target.value)}
              disabled={!active}
              onBlur={focusRFID}
              autoComplete="off"
              inputMode="none"
            />
            {/* showNfcButton && (
              <NfcButton
                nfcReadingEnabled={nfcReadingEnabled}
                setNfcReadingEnabled={setNfcReadingEnabled}
                handleChange={handleChange}
              />
            ) */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RFIDField;
