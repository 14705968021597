import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function create(orderItems, rfid, token) {
  const { data } = await axios.post(routes.ordersIndex, {
    orderItems,
    rfid,
    token,
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
