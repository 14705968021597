import React from 'react';

import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";


export default function ConfirmationModal({
  onHide = () => {},
  onConfirm = () => {},
  onDecline = () => {},
  show = false,
  prompt = 'Are you sure?',
}) {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Body className="text-center">
        <Row>
          <Col>
            <span style={{ fontSize: 32 }}>
              {prompt}
            </span>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={{ span: 4, offset: 1 }}>
            <Button
              className="form-control"
              style={{ fontSize: 24 }}
              variant="success"
              onClick={onConfirm}
            >
              Yes
            </Button>
          </Col>

          <Col xs={{ span: 4, offset: 2 }}>
            <Button
              className="form-control"
              style={{ fontSize: 24 }}
              variant="danger"
              onClick={onDecline}
            >
              No
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
