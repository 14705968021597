import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function list(search = null) {
  const { data } = await axios.get(routes.itemsIndex, {
    ...AXIOS_DEFAULT_CONFIG,
    params: {
      search,
    },
  });
  return data;
}
