import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./CashOrCreditModal.sass";

const CashOrCreditModal = ({
  show = false,
  showFocButton = false,
  onHide = () => {},
  handleChoice = () => {},
  price = null,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered className="method-modal">
      <Modal.Header closeButton={false} style={{ justifyContent: "end" }}>
        <FontAwesomeIcon icon={faTimes} size={"lg"} onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        {price && <h3 className="body-title text-center">{price} EGP</h3>}
        <div className="method-container">
          <button className="method-btn" onClick={() => handleChoice("cash")}>
            Cash
          </button>
          <button className="method-btn" onClick={() => handleChoice("card")}>
            Credit Card
          </button>
          {showFocButton && (
            <button className="method-btn" onClick={() => handleChoice("foc")}>
              FOC
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CashOrCreditModal;
