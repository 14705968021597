import axios from 'axios';

import { AXIOS_DEFAULT_CONFIG, routes } from '../../constants';


export default async function assignRfid(pk, rfid, purchaseType = null) {
  let body = { rfid }
  if (purchaseType != null) {
    body.purchaseType = purchaseType;
  }
  const { data } = await axios.patch(routes.customerRfid(pk), {
    ...body
  }, AXIOS_DEFAULT_CONFIG);

  return data;
}
